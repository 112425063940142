import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import Box from '@mui/material/Box';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CancelIcon from '@mui/icons-material/Cancel';
import MedicalServicesIcon from '@mui/icons-material/MedicalServices';
import KioskButton from '../theme/Button';

export default function SimpleDialog(props) {
    const { handleClose, open } = props;

    return (
        <Dialog onClose={handleClose} open={open} fullWidth={true} maxWidth={'md'}>
            <Box
                sx={{
                    backgroundColor: 'white',
                    zIndex: 20,
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <DialogTitle sx={{ color: 'black' }}>
                        <FormattedMessage
                            id="selectOption"
                            defaultMessage="Please select an option"
                        />
                    </DialogTitle>
                    <IconButton aria-label="delete" onClick={handleClose}>
                        <CancelIcon />
                    </IconButton>
                </Box>
                <Box sx={{ width: 'calc(100% - 60px)', margin: '0 auto' }}>
                    <br />
                    <div style={{ width: '100%' }}>
                        <KioskButton
                            variant="outlined"
                            iconbgcolor="#F67C1E"
                            size="large"
                            startIcon={<MedicalServicesIcon />}
                        >
                            Free Check
                        </KioskButton>
                    </div>
                    <br />
                    <div style={{ width: '100%' }}>
                        <KioskButton
                            variant="outlined"
                            iconbgcolor="#2EB777"
                            size="large"
                            startIcon={<MedicalServicesIcon />}
                        >
                            Speak to a Medical Professional
                        </KioskButton>
                    </div>
                    <br />
                    <br />
                </Box>
            </Box>
        </Dialog>
    );
}
