const messages = {
    english: {
        selectLanguage: 'Please select your language',
        backMainMenu: 'Back to Main Menu',
        helpSubtitle: 'How can we help you today?',
        healthWellness: 'Health and Wellness Check',
        denSurvey: 'DEN Customer Service Survey',
        emergencyAccess: 'Emergency Access Services',
        multilingualCalling: 'Multilingual Calling',
        freeCalling: 'Free Calling',
        denLiveVideo: 'DEN Live Video Agent',
        multilingualChatRoom: 'Multilingual Chat Room',
        denMarketplace: 'DEN Airport Marketplace',
        qrCodeCreator: 'Global Marketplace',
        ttyTtd: 'TTY/TDD is for video relay services for the deaf and hearing impaired',
        airlineAgents: 'Airline Agents',
        personalCall: 'Personal Call',
        phoneNumber: 'Phone Number',
        selectAirline: 'Select your airline agent',
        airlineCalling: 'Airline Calling',
        connect: 'Connect',
        selectOption: 'Select an option',
    },
    spanish: {
        selectLanguage: 'Por favor seleccione su idioma',
        backMainMenu: 'Regresar al menú principal',
        helpSubtitle: '¿Cómo podemos ayudarle hoy?',
        healthWellness: 'Control de salud y bienestar',
        denSurvey: 'Encuesta de servicio al cliente de DEN',
        emergencyAccess: 'Servicios de acceso de emergencia',
        multilingualCalling: 'Llamadas multilingües',
        freeCalling: 'Llamadas gratis',
        denLiveVideo: 'Agente de vídeo en vivo de DEN',
        multilingualChatRoom: 'Sala de chat multilingüe',
        denMarketplace: 'Mercado del Aeropuerto DEN',
        qrCodeCreator: 'Mercado GLobal',
        ttyTtd: 'TTY/TDD es para servicios de retransmisión de video para personas sordas y con problemas de audición.',
        airlineAgents: 'Agentes de aerolíneas',
        personalCall: 'llamada personal',
        phoneNumber: 'Número de teléfono',
        selectAirline: 'Seleccione su agente aéreo',
        airlineCalling: 'Llamadas de aerolíneas',
        connect: 'Conectar',
    },
    arabic: {
        selectLanguage: 'الرجاء اختيار لغتك',
        backMainMenu: 'العودة إلى القائمة الرئيسية',
        helpSubtitle: 'كيف يمكننا مساعدتك اليوم؟',
        healthWellness: 'فحص الصحة والعافية',
        denSurvey: 'استبيان خدمة العملاء DEN',
        emergencyAccess: 'خدمات الوصول في حالات الطوارئ',
        multilingualCalling: 'مكالمات متعددة اللغات',
        freeCalling: 'اتصال مجاني',
        denLiveVideo: 'وكيل الفيديو المباشر DEN',
        multilingualChatRoom: 'غرفة دردشة متعددة اللغات',
        denMarketplace: 'سوق مطار DEN',
        qrCodeCreator: 'السوق العالمية',
        ttyTtd: 'TTY/TDD مخصص لخدمات ترحيل الفيديو للصم وضعاف السمع',
        airlineAgents: 'وكلاء شركات الطيران',
        personalCall: 'مكالمة شخصية',
        phoneNumber: 'رقم التليفون',
        selectAirline: 'اختر وكيل الطيران الخاص بك',
        airlineCalling: 'مكالمات طيران',
        connect: 'يتصل',
    },
    'swahili-kenya': {
        selectLanguage: 'Tafadhali chagua lugha yako',
        backMainMenu: 'Rudi kwenye Menyu Kuu',
        helpSubtitle: 'Tunaweza kukusaidia vipi leo?',
        healthWellness: 'Uchunguzi wa Afya na Ustawi',
        denSurvey: 'Utafiti wa Huduma kwa Wateja wa DEN',
        emergencyAccess: 'Huduma za Ufikiaji wa Dharura',
        multilingualCalling: 'Wito kwa lugha nyingi',
        freeCalling: 'Kupiga Simu Bila Malipo',
        denLiveVideo: 'Wakala wa Video wa DEN Live',
        multilingualChatRoom: 'Chumba cha Gumzo kwa Lugha nyingi',
        denMarketplace: 'Soko la Uwanja wa Ndege wa DEN',
        qrCodeCreator: 'Soko la Kimataifa',
        ttyTtd: 'TTY/TDD ni kwa ajili ya huduma za relay video kwa viziwi na walemavu wa kusikia',
        airlineAgents: 'Mawakala wa mashirika ya ndege',
        personalCall: 'Simu ya Kibinafsi',
        phoneNumber: 'Nambari ya Simu',
        selectAirline: 'Chagua wakala wako wa ndege',
        airlineCalling: 'Kupiga simu kwa ndege',
        connect: 'Unganisha',
    },
    no: {
        selectLanguage: 'Vennligst velg språket ditt',
        backMainMenu: 'Tilbake til hovedmenyen',
        helpSubtitle: 'Hvordan kan vi hjelpe deg i dag?',
        healthWellness: 'Helse- og velværesjekk',
        denSurvey: 'DEN kundeserviceundersøkelse',
        emergencyAccess: 'Nødtilgangstjenester',
        multilingualCalling: 'Flerspråklig samtale',
        freeCalling: 'Gratis samtale',
        denLiveVideo: 'DEN Norske Live Video Agent',
        multilingualChatRoom: 'Flerspråklig chatterom',
        denMarketplace: 'Flyplassens Markedsplass',
        qrCodeCreator: 'Global markedsplass',
        ttyTtd: 'TTY/TDD er for videorelétjenester for døve og hørselshemmede',
        airlineAgents: 'Flyselskapets agenter',
        personalCall: 'Personlig samtale',
        phoneNumber: 'Telefonnummer',
        selectAirline: 'Velg flyselskapets agent',
        airlineCalling: 'Flyselskap ringer',
        connect: 'Koble',
    },
    maltese: {
        selectLanguage: 'Jekk jogħġbok agħżel il-lingwa tiegħek',
        backMainMenu: 'Lura għall-Menu Prinċipali',
        helpSubtitle: 'Kif nistgħu ngħinuk illum?',
        healthWellness: 'Kontroll tas-Saħħa u l-Benessri',
        denSurvey: 'Stħarriġ dwar is-Servizz tal-Klijent tad-DEN',
        emergencyAccess: "Servizzi ta' Aċċess ta' Emerġenza",
        multilingualCalling: 'Sejħa multilingwi',
        freeCalling: "Sejħa b'xejn",
        denLiveVideo: 'Aġent tal-Vidjo DEN',
        multilingualChatRoom: 'Chat Room Multilingwi',
        denMarketplace: 'Suq tal-Ajruport DEN',
        qrCodeCreator: 'Suq Globali',
        ttyTtd: "TTY/TDD huwa għal servizzi ta' video relay għal persuni neqsin mis-smigħ u neqsin mis-smigħ",
        airlineAgents: 'Aġenti tal-linji tal-ajru',
        personalCall: 'Sejħa Personali',
        phoneNumber: 'Numru tat-telefon',
        selectAirline: 'Agħżel l-aġent tal-linja tal-ajru tiegħek',
        airlineCalling: 'Sejħa tal-Ajru',
        connect: 'Qabbad',
    },
    cebuano: {
        selectLanguage: 'Palihug pilia ang imong pinulongan',
        backMainMenu: 'Balik sa Panguna nga Menu',
        helpSubtitle: 'Unsaon namo pagtabang kanimo karon?',
        healthWellness: 'Pagsusi sa Panglawas ug Kaayohan',
        denSurvey: 'Surbi sa Serbisyo sa Kustomer sa DEN',
        emergencyAccess: 'Mga Serbisyo para sa Emerhensya nga Pag-access',
        multilingualCalling: 'Multilingual nga pagtawag',
        freeCalling: 'Libre nga Pagtawag',
        denLiveVideo: 'Ang Live Video Ahente',
        multilingualChatRoom: 'Multilingual Nga Chat Lawak',
        denMarketplace: 'Paliparan sa Tugpahanan sa DEN',
        qrCodeCreator: 'Global Merkado',
        ttyTtd: 'Ang TTY/TDD para sa mga serbisyo sa video relay para sa mga bungol ug may diperensya sa pandungog',
        airlineAgents: 'Mga ahente sa eroplano',
        personalCall: 'Personal nga Tawag',
        phoneNumber: 'Numero sa Telepono',
        selectAirline: 'Pilia ang imong ahente sa ayroplano',
        airlineCalling: 'Pagtawag sa eroplano',
        connect: 'sumpaysumpaya',
    },
    fijian: {
        selectLanguage: 'Yalovinaka digitaka na nomu vosa',
        backMainMenu: 'Lesu ki na Menu Levu',
        helpSubtitle: 'E rawa vakacava me keitou vukei iko nikua?',
        healthWellness: 'Jeke ni Bulabula kei na Bulabula',
        denSurvey: 'Vakadidike ni Veiqaravi ni Kasitama ni DEN',
        emergencyAccess: 'Veiqaravi ni Curu ki na Leqa Tubukoso',
        multilingualCalling: 'Veikacivi Vakayabaki',
        freeCalling: 'Veikacivi Galala',
        denLiveVideo: 'DEN Itini ni Vidio Bula',
        multilingualChatRoom: 'Rumu ni Veitalanoa vakayabaki',
        denMarketplace: 'DEN ni rara ni waqavuka Marketplace',
        qrCodeCreator: 'Makete e Vuravura Raraba',
        ttyTtd: 'TTY/TDD e baleta na veiqaravi ni qiqi ena vidio me baleti ira na didivara kei na didivara',
        airlineAgents: 'Mata ni waqavuka',
        personalCall: 'Veikacivi Yadua',
        phoneNumber: 'Naba ni talevoni',
        selectAirline: 'Digitaka na nomu mata ni waqavuka',
        airlineCalling: 'Veikacivi ni Kabani ni Waqavuka',
        connect: 'Sema',
    },
    lu: {
        selectLanguage: 'Wielt w.e.g. Är Sprooch',
        backMainMenu: 'Zréck op den Haaptmenü',
        helpSubtitle: 'Wéi kënne mir Iech haut hëllefen?',
        healthWellness: 'Gesondheets- a Wellnesscheck',
        denSurvey: 'De Client Ass Survey',
        emergencyAccess: 'Noutzougangsservicer',
        multilingualCalling: 'Méisproocheg Uruff',
        freeCalling: 'Gratis Uruff',
        denLiveVideo: 'In De Comments',
        multilingualChatRoom: 'Méisproocheg Chatraum',
        denMarketplace: 'DEN Airport Maartplaz',
        qrCodeCreator: 'Globale Maartplaz',
        ttyTtd: 'TTY/TDD ass fir Videorelaisservicer fir Daaf an Hörbehënnerten',
        airlineAgents: 'Agente Vu Fluchgesellschaften',
        personalCall: 'Perséinlech Uruff',
        phoneNumber: 'Telefonsnummer',
        selectAirline: 'Wielt Äre Airline Agent',
        airlineCalling: 'Fluchgesellschaft urufft',
        connect: 'Verbinden',
    },
    galician: {
        selectLanguage: 'Selecciona o teu idioma',
        backMainMenu: 'Volver ao menú principal',
        helpSubtitle: 'Como podemos axudarche hoxe?',
        healthWellness: 'Verificación de Saúde e Benestar',
        denSurvey: 'Enquisa de atención ao cliente DEN',
        emergencyAccess: 'Servizos de acceso de emerxencia',
        multilingualCalling: 'Chamada multilingüe',
        freeCalling: 'Chamada gratuíta',
        denLiveVideo: 'O Axente De Vídeo En Directo',
        multilingualChatRoom: 'Sala de chat multilingüe',
        denMarketplace: 'Mercado do aeroporto de DEN',
        qrCodeCreator: 'Mercado global',
        ttyTtd: 'TTY/TDD é para servizos de retransmisión de vídeo para xordos e con discapacidade auditiva',
        airlineAgents: 'Axentes de compañías aéreas',
        personalCall: 'Chamada persoal',
        phoneNumber: 'Número de teléfono',
        selectAirline: 'Seleccione o axente da súa compañía aérea',
        airlineCalling: 'Chamadas aéreas',
        connect: 'Conectar',
    },
    'basque-spain': {
        selectLanguage: 'Mesedez, hautatu zure hizkuntza',
        backMainMenu: 'Itzuli menu nagusira',
        helpSubtitle: 'Nola lagundu dezakegu gaur?',
        healthWellness: 'Osasun eta Ongizate Kontrola',
        denSurvey: 'DEN Bezeroarentzako Zerbitzuaren Inkesta',
        emergencyAccess: 'Larrialdietarako Sarbide Zerbitzuak',
        multilingualCalling: 'Dei eleanitza',
        freeCalling: 'Doako deia',
        denLiveVideo: 'DEN Zuzeneko Bideo Agentea',
        multilingualChatRoom: 'Elkarrizketa gela eleanitza',
        denMarketplace: 'DEN aireportuko merkatua',
        qrCodeCreator: 'Merkatu globala',
        ttyTtd: 'TTY/TDD gorrentzako eta entzumen-urritasuna dutenentzako bideo-erreprodukzio zerbitzuetarako da',
        airlineAgents: 'Aire konpainietako agenteak',
        personalCall: 'Dei pertsonala',
        phoneNumber: 'Telefono zenbakia',
        selectAirline: 'Hautatu zure aire konpainiako agentea',
        airlineCalling: 'Aire-konpainiaren deiak',
        connect: 'Konektatu',
    },
    rw: {
        selectLanguage: 'Nyamuneka hitamo ururimi rwawe',
        backMainMenu: 'Subira kuri Main Main',
        helpSubtitle: 'Nigute dushobora kugufasha uyu munsi?',
        healthWellness: "Kugenzura Ubuzima n'Ubwiza",
        denSurvey: 'Ubushakashatsi bwa DEN Serivisi',
        emergencyAccess: 'Serivisi zihutirwa',
        multilingualCalling: 'Guhamagara indimi nyinshi',
        freeCalling: 'Guhamagara kubuntu',
        denLiveVideo: 'DEN Live Umukozi wa VIdeo',
        multilingualChatRoom: 'Icyumba cyo kuganiriramo indimi nyinshi',
        denMarketplace: "Isoko ry'ikibuga cy'indege DEN",
        qrCodeCreator: 'Isoko ryisi yose',
        ttyTtd: 'TTY / TDD ni serivisi zo gutanga amashusho ku batumva no kutumva',
        airlineAgents: "Abakozi b'indege",
        personalCall: "Ihamagarwa ry'umuntu",
        phoneNumber: 'Numero ya Terefone',
        selectAirline: "Hitamo umukozi wawe w'indege",
        airlineCalling: "Guhamagara kw'indege",
        connect: 'Guhuza',
    },
    irish: {
        selectLanguage: 'Roghnaigh do theanga le do thoil',
        backMainMenu: 'Ar ais go dtí an Príomh-Roghchlár',
        helpSubtitle: 'Conas is féidir linn cabhrú leat inniu?',
        healthWellness: 'Seiceáil Sláinte agus Folláine',
        denSurvey: 'DEN Suirbhé ar Sheirbhís do Chustaiméirí',
        emergencyAccess: 'Seirbhísí Rochtana Éigeandála',
        multilingualCalling: 'Glaonna ilteangacha',
        freeCalling: 'Glaoch saor in aisce,',
        denLiveVideo: 'DEN Gníomhaire Físeáin Beo',
        multilingualChatRoom: 'Seomra Comhrá Ilteangach',
        denMarketplace: 'Margadh Aerfoirt DEN',
        qrCodeCreator: 'Margadh Domhanda',
        ttyTtd: 'Tá TTY/TDD le haghaidh seirbhísí sealaíochta físe do dhaoine bodhra agus lagéisteachta',
        airlineAgents: 'Gníomhairí aerlíne',
        personalCall: 'Glao Pearsanta',
        phoneNumber: 'Uimhir teileafón',
        selectAirline: 'Roghnaigh do ghníomhaire aerlíne',
        airlineCalling: 'Glaoch Aerlíne',
        connect: 'Ceangal',
    },
    bangla: {
        selectLanguage: 'অনুগ্রহ করে আপনার ভাষা নির্বাচন করুন',
        backMainMenu: 'প্রধান মেনুতে ফিরে যান',
        helpSubtitle: 'আজ আমরা কিভাবে আপনাকে সাহায্য করতে পারি?',
        healthWellness: 'স্বাস্থ্য এবং সুস্থতা পরীক্ষা',
        denSurvey: 'DEN কাস্টমার সার্ভিস সার্ভে',
        emergencyAccess: 'জরুরি অ্যাক্সেস পরিষেবা',
        multilingualCalling: 'বহুভাষিক কলিং',
        freeCalling: 'ফ্রি কলিং',
        denLiveVideo: 'ডেন লাইভ ভিডিও এজেন্ট',
        multilingualChatRoom: 'বহুভাষিক চ্যাট রুম',
        denMarketplace: 'ডেন এয়ারপোর্ট মার্কেটপ্লেস',
        qrCodeCreator: 'গ্লোবাল মার্কেটপ্লেস',
        ttyTtd: 'TTY/TDD হল বধির এবং শ্রবণ প্রতিবন্ধীদের জন্য ভিডিও রিলে পরিষেবার জন্য',
        airlineAgents: 'এয়ারলাইন এজেন্ট',
        personalCall: 'ব্যক্তিগত কল',
        phoneNumber: 'ফোন নম্বর',
        selectAirline: 'আপনার এয়ারলাইন এজেন্ট নির্বাচন করুন',
        airlineCalling: 'এয়ারলাইন কলিং',
        connect: 'সংযোগ',
    },
    zl: {
        selectLanguage: 'Sicela ukhethe ulimi lwakho',
        backMainMenu: 'Buyela ku-Main Menu',
        helpSubtitle: 'Singakusiza kanjani namuhla?',
        healthWellness: 'Ukuhlola Impilo Nokuphila',
        denSurvey: 'DEN Customer Service Survey',
        emergencyAccess: 'Izinkonzo Zokufinyelela Eziphuthumayo',
        multilingualCalling: 'Ukushaya ngezilimi eziningi',
        freeCalling: 'Ukushaya Kwamahhala',
        denLiveVideo: 'I-Agent Yevidiyo Ebukhoma',
        multilingualChatRoom: 'Igumbi Lengxoxo Yezilimi Eziningi',
        denMarketplace: 'Imakethe Yesikhumulo Sezindiza',
        qrCodeCreator: 'Imakethe Yomhlaba Wonke',
        ttyTtd: 'I-TTY/TDD eyesevisi yokudlulisa ividiyo kwabangezwa nabangezwa',
        airlineAgents: 'Abenzeli bezindiza',
        personalCall: 'Ucingo lomuntu siqu',
        phoneNumber: 'Inombolo Yocingo',
        selectAirline: 'Khetha i-ejenti yakho yendiza',
        airlineCalling: 'Ukushaya Ngendiza',
        connect: 'Xhuma',
    },
    punjabi: {
        selectLanguage: 'ਕਿਰਪਾ ਕਰਕੇ ਆਪਣੀ ਭਾਸ਼ਾ ਚੁਣੋ',
        backMainMenu: 'ਮੁੱਖ ਮੇਨੂ ਤੇ ਵਾਪਸ',
        helpSubtitle: 'ਅੱਜ ਅਸੀਂ ਤੁਹਾਡੀ ਕਿਵੇਂ ਮਦਦ ਕਰ ਸਕਦੇ ਹਾਂ?',
        healthWellness: 'ਸਿਹਤ ਅਤੇ ਤੰਦਰੁਸਤੀ ਜਾਂਚ',
        denSurvey: 'DEN ਗਾਹਕ ਸੇਵਾ ਸਰਵੇਖਣ',
        emergencyAccess: 'ਐਮਰਜੈਂਸੀ ਪਹੁੰਚ ਸੇਵਾਵਾਂ',
        multilingualCalling: 'ਬਹੁ-ਭਾਸ਼ਾਈ ਕਾਲਿੰਗ',
        freeCalling: 'ਮੁਫ਼ਤ ਕਾਲਿੰਗ',
        denLiveVideo: 'DEN ਲਾਈਵ ਵੀਡੀਓ ਏਜੰਟ',
        multilingualChatRoom: 'ਬਹੁ-ਭਾਸ਼ਾਈ ਚੈਟ ਰੂਮ',
        denMarketplace: 'DEN ਏਅਰਪੋਰਟ ਬਾਜ਼ਾਰ',
        qrCodeCreator: 'ਗਲੋਬਲ ਮਾਰਕੀਟਪਲੇਸ',
        ttyTtd: 'TTY/TDD ਬੋਲ਼ੇ ਅਤੇ ਘੱਟ ਸੁਣਨ ਵਾਲੇ ਲੋਕਾਂ ਲਈ ਵੀਡੀਓ ਰੀਲੇਅ ਸੇਵਾਵਾਂ ਲਈ ਹੈ',
        airlineAgents: 'ਏਅਰਲਾਈਨ ਏਜੰਟ',
        personalCall: 'ਨਿੱਜੀ ਕਾਲ',
        phoneNumber: 'ਫੋਨ ਨੰਬਰ',
        selectAirline: 'ਆਪਣਾ ਏਅਰਲਾਈਨ ਏਜੰਟ ਚੁਣੋ',
        airlineCalling: 'ਏਅਰਲਾਈਨ ਕਾਲਿੰਗ',
        connect: 'ਕਨੈਕਟ ਕਰੋ',
    },
    'swahili-tanzania': {
        selectLanguage: 'Tafadhali chagua lugha yako',
        backMainMenu: 'Rudi kwenye Menyu Kuu',
        helpSubtitle: 'Tunaweza kukusaidia vipi leo?',
        healthWellness: 'Uchunguzi wa Afya na Ustawi',
        denSurvey: 'Utafiti wa Huduma kwa Wateja wa DEN',
        emergencyAccess: 'Huduma za Ufikiaji wa Dharura',
        multilingualCalling: 'Wito kwa lugha nyingi',
        freeCalling: 'Kupiga Simu Bila Malipo',
        denLiveVideo: 'Wakala wa Video wa DEN Live',
        multilingualChatRoom: 'Chumba cha Gumzo kwa Lugha nyingi',
        denMarketplace: 'Soko la Uwanja wa Ndege wa DEN',
        qrCodeCreator: 'Soko la Ulimwenguni',
        ttyTtd: 'TTY/TDD ni kwa ajili ya huduma za relay video kwa viziwi na walemavu wa kusikia',
        airlineAgents: 'Mawakala wa mashirika ya ndege',
        personalCall: 'Simu ya Kibinafsi',
        phoneNumber: 'Nambari ya Simu',
        selectAirline: 'Chagua wakala wako wa ndege',
        airlineCalling: 'Kupiga simu kwa ndege',
        connect: 'Unganisha',
    },
    is: {
        selectLanguage: 'Vinsamlegast veldu tungumálið þitt',
        backMainMenu: 'Til baka í aðalvalmynd',
        helpSubtitle: 'Hvernig getum við hjálpað þér í dag?',
        healthWellness: 'Heilsu- og vellíðunarathugun',
        denSurvey: 'Þjónustukönnun DEN',
        emergencyAccess: 'Neyðaraðgangsþjónusta',
        multilingualCalling: 'Fjöltyngt símtal',
        freeCalling: 'Ókeypis símtöl',
        denLiveVideo: 'DEN Lifandi Umboðsmaður',
        multilingualChatRoom: 'Fjöltyngt spjallrás',
        denMarketplace: 'Flugvöllurinn',
        qrCodeCreator: 'Alþjóðlegur markaðstorg',
        ttyTtd: 'TTY/TDD er fyrir myndsendingarþjónustu fyrir heyrnarlausa og heyrnarskerta',
        airlineAgents: 'Umboðsmenn flugfélaga',
        personalCall: 'Persónulegt símtal',
        phoneNumber: 'Símanúmer',
        selectAirline: 'Veldu umboðsmann flugfélagsins',
        airlineCalling: 'Flugfélag að hringja',
        connect: 'Tengdu',
    },
    sinhala: {
        selectLanguage: 'කරුණාකර ඔබේ භාෂාව තෝරන්න',
        backMainMenu: 'ආපසු ප්‍රධාන මෙනුවට',
        helpSubtitle: 'අපි අද ඔබට උදව් කරන්නේ කෙසේද?',
        healthWellness: 'සෞඛ්‍ය සහ සුවතා පරීක්‍ෂාව',
        denSurvey: 'DEN පාරිභෝගික සේවා සමීක්ෂණය',
        emergencyAccess: 'හදිසි ප්‍රවේශ සේවා',
        multilingualCalling: 'බහුභාෂා ඇමතුම්',
        freeCalling: 'නොමිලේ ඇමතුම්',
        denLiveVideo: 'DEN සජීවී වීඩියෝ නියෝජිතයා',
        multilingualChatRoom: 'බහුභාෂා කතාබස් කාමරය',
        denMarketplace: 'DEN ගුවන්තොටුපල වෙළඳපොළ',
        qrCodeCreator: 'ගෝලීය වෙළඳපොළ',
        ttyTtd: 'TTY/TDD යනු බිහිරි සහ ශ්‍රවණාබාධිතයින් සඳහා වීඩියෝ රිලේ සේවා සඳහා වේ',
        airlineAgents: 'ගුවන් සේවා නියෝජිතයන්',
        personalCall: 'පුද්ගලික ඇමතුම',
        phoneNumber: 'දුරකථන අංකය',
        selectAirline: 'ඔබේ ගුවන් සේවා නියෝජිතයා තෝරන්න',
        airlineCalling: 'ගුවන් ඇමතුම්',
        connect: 'සම්බන්ධ කරන්න',
    },
    az: {
        selectLanguage: 'Lütfən, dilinizi seçin',
        backMainMenu: 'Əsas menyuya qayıt',
        helpSubtitle: 'Bu gün sizə necə kömək edə bilərik?',
        healthWellness: 'Sağlamlıq və rifah yoxlanışı',
        denSurvey: 'DEN Müştəri Xidmətləri Sorğusu',
        emergencyAccess: 'Fövqəladə Giriş Xidmətləri',
        multilingualCalling: 'Çoxdilli zəng',
        freeCalling: 'Pulsuz Zəng',
        denLiveVideo: 'Canlı video agenti',
        multilingualChatRoom: 'Çoxdilli Söhbət Otağı',
        denMarketplace: 'Hava limanında Marketplace',
        qrCodeCreator: 'Qlobal Bazar',
        ttyTtd: 'TTY/TDD kar və eşitmə qüsurlu insanlar üçün video relay xidmətləri üçündür',
        airlineAgents: 'Aviaşirkət agentləri',
        personalCall: 'Şəxsi Zəng',
        phoneNumber: 'Telefon nömrəsi',
        selectAirline: 'Aviagentinizi seçin',
        airlineCalling: 'Aviaşirkətdən zəng',
        connect: 'qoşulmaq',
    },
    mrj: {
        selectLanguage: 'Пожалуйста, тӓ айырендӓ йӹлмӹ',
        backMainMenu: 'тӹнг меню пӧртӹлеш',
        helpSubtitle: 'тагачы мӓ тӓ палшен кердӹдӓ?',
        healthWellness: 'здороваэш проверка состоянижӹ',
        denSurvey: 'Ядыштмаш клиентвлӓ DEN',
        emergencyAccess: 'корны Служба экстренный',
        multilingualCalling: 'ужаш многоязычный',
        freeCalling: 'Йӓрӓ звонок',
        denLiveVideo: 'DEN Видеоагент Live',
        multilingualChatRoom: 'чат Многоязычный',
        denMarketplace: 'den площадкышты торгейӹмӓш аэропорт',
        qrCodeCreator: 'площадкышты торговӹй Глобальный',
        ttyTtd: 'TTY/служат дӓ пӹц ӹштӓш сӧрен ретрансляция видео слабослышащий TDD',
        airlineAgents: 'Агентвлӓжӹ авиакомпаний',
        personalCall: 'Личный звонок',
        PhoneNumber: 'телефон номержӹ',
        selectAirline: 'тӓ айырендӓ агентвлӓжӹ авиакомпаний',
        airlineCalling: 'авиакомпаний Ӱжӹктӓш',
        connect: 'Подключаться',
    },
    bosnian: {
        selectLanguage: 'Molimo odaberite svoj jezik',
        backMainMenu: 'Nazad na glavni meni',
        helpSubtitle: 'Kako vam možemo pomoći danas?',
        healthWellness: 'Provjera zdravlja i dobrobiti',
        denSurvey: 'DEN anketa o korisničkoj službi',
        emergencyAccess: 'Usluge pristupa u hitnim slučajevima',
        multilingualCalling: 'Višejezično pozivanje',
        freeCalling: 'Besplatno pozivanje',
        denLiveVideo: 'Agent Uživo',
        multilingualChatRoom: 'Višejezična soba za ćaskanje',
        denMarketplace: 'pijaca aerodroma DEN',
        qrCodeCreator: 'Globalno tržište',
        ttyTtd: 'TTY/TDD je za video relejne usluge za gluhe i nagluhe',
        airlineAgents: 'Avionski agenti',
        personalCall: 'Lični poziv',
        phoneNumber: 'Broj telefona',
        selectAirline: 'Odaberite svog agenta aviokompanije',
        airlineCalling: 'Pozivanje avionom',
        connect: 'Poveži se',
    },
    'albanian-albania': {
        selectLanguage: 'Ju lutemi zgjidhni gjuhën tuaj',
        backMainMenu: 'Kthehu te menyja kryesore',
        helpSubtitle: "Si mund t'ju ndihmojmë sot?",
        healthWellness: 'Kontrolli i shëndetit dhe mirëqenies',
        denSurvey: 'Anketa e Shërbimit të Kli   entit DEN',
        emergencyAccess: 'Shërbimet e qasjes së urgjencës',
        multilingualCalling: 'Thirrje shumëgjuhëshe',
        freeCalling: 'Thirrje falas',
        denLiveVideo: 'Agjent I Gjallë',
        multilingualChatRoom: 'Dhomë Bisede Shumëgjuhëshe',
        denMarketplace: 'Tregu I Aeroportit DEN',
        qrCodeCreator: 'Tregu Global',
        ttyTtd: 'TTY/TDD është për shërbimet e transmetimit të videove për personat e shurdhër dhe me dëgjim të dëmtuar',
        airlineAgents: 'Agjentët e linjës ajrore',
        personalCall: 'Telefonatë personale',
        phoneNumber: 'Numri i telefonit',
        selectAirline: 'Zgjidh agjentin tënd të linjës ajrore',
        airlineCalling: 'Thirrje ajrore',
        connect: 'Lidhu',
    },
    tamil: {
        selectLanguage: 'உங்கள் மொழியைத் தேர்ந்தெடுக்கவும்',
        backMainMenu: 'முதன்மை மெனுவிற்குத் திரும்பு',
        helpSubtitle: 'இன்று நாங்கள் உங்களுக்கு எப்படி உதவ முடியும்?',
        healthWellness: 'உடல்நலம் மற்றும் ஆரோக்கிய சோதனை',
        denSurvey: 'DEN வாடிக்கையாளர் சேவை கணக்கெடுப்பு',
        emergencyAccess: 'அவசர அணுகல் சேவைகள்',
        multilingualCalling: 'பன்மொழி அழைப்பு',
        freeCalling: 'இலவச அழைப்பு',
        denLiveVideo: 'DEN நேரடி வீடியோ முகவர்',
        multilingualChatRoom: 'பன்மொழி அரட்டை அறை',
        denMarketplace: 'DEN விமான நிலைய சந்தை',
        qrCodeCreator: 'உலகளாவிய சந்தை',
        ttyTtd: 'TTY/TDD என்பது காது கேளாதோர் மற்றும் செவித்திறன் குறைபாடுடையவர்களுக்கான வீடியோ ரிலே சேவைகளுக்கானது',
        airlineAgents: 'விமான முகவர்கள்',
        personalCall: 'தனிப்பட்ட அழைப்பு',
        phoneNumber: 'தொலைபேசி எண்',
        selectAirline: 'உங்கள் விமான முகவரைத் தேர்ந்தெடு',
        airlineCalling: 'விமான அழைப்பு',
        connect: 'இணை',
    },
    th: {
        selectLanguage: 'โปรดเลือกภาษาของคุณ',
        backMainMenu: 'กลับสู่เมนูหลัก',
        helpSubtitle: 'วันนี้เราจะช่วยคุณได้อย่างไร',
        healthWellness: 'การตรวจสุขภาพและความเป็นอยู่ที่ดี',
        denSurvey: 'แบบสำรวจบริการลูกค้า DEN',
        emergencyAccess: 'บริการการเข้าถึงเหตุฉุกเฉิน',
        multilingualCalling: 'การโทรหลายภาษา',
        freeCalling: 'โทรฟรี',
        denLiveVideo: 'ตัวแทนวิดีโอสด DEN',
        multilingualChatRoom: 'ห้องสนทนาหลายภาษา',
        denMarketplace: 'ตลาดสนามบิน DEN',
        qrCodeCreator: 'ตลาดระดับโลก',
        ttyTtd: 'TTY/TDD ใช้สำหรับบริการถ่ายทอดวิดีโอสำหรับคนหูหนวกและผู้บกพร่องทางการได้ยิน',
        airlineAgents: 'ตัวแทนสายการบิน',
        personalCall: 'การโทรส่วนตัว',
        phoneNumber: 'หมายเลขโทรศัพท์',
        selectAirline: 'เลือกตัวแทนสายการบินของคุณ',
        airlineCalling: 'การโทรของสายการบิน',
        connect: 'เชื่อมต่อ',
    },
    mn: {
        selectLanguage: 'Хэлээ сонгоно уу',
        backMainMenu: 'Үндсэн цэс рүү буцах',
        helpSubtitle: 'Бид өнөөдөр танд яаж туслах вэ?',
        healthWellness: 'Эрүүл мэнд, эрүүл мэндийг шалгах',
        denSurvey: 'DEN хэрэглэгчийн үйлчилгээний судалгаа',
        emergencyAccess: 'Яаралтай тусламжийн үйлчилгээ',
        multilingualCalling: 'Олон хэлээр дуудлага хийх',
        freeCalling: 'Үнэгүй дуудлага',
        denLiveVideo: 'Амьд Агент',
        multilingualChatRoom: 'Олон хэлтэй чатын өрөө',
        denMarketplace: 'DEN нисэх онгоцны буудлын зах зээл',
        qrCodeCreator: 'Дэлхийн зах зээл',
        ttyTtd: 'TTY/TDD нь дүлий болон сонсголын бэрхшээлтэй хүмүүст зориулсан видео дамжуулах үйлчилгээнд зориулагдсан',
        airlineAgents: 'Агаарын агентууд',
        personalCall: 'Хувийн дуудлага',
        phoneNumber: 'Утасны дугаар',
        selectAirline: 'Агаарын компанийн төлөөлөгчөө сонгоно уу',
        airlineCalling: 'Агаарын дуудлага',
        connect: 'Холбох',
    },
    uk: {
        selectLanguage: 'Будь ласка, виберіть свою мову',
        backMainMenu: 'Назад до головного меню',
        helpSubtitle: 'Чим ми можемо допомогти вам сьогодні?',
        healthWellness: "Перевірка здоров'я та добробуту",
        denSurvey: 'Опитування служби обслуговування клієнтів DEN',
        emergencyAccess: 'Служби екстреного доступу',
        multilingualCalling: 'Багатомовний виклик',
        freeCalling: 'Безкоштовний дзвінок',
        denLiveVideo: 'Агент DEN Live Video',
        multilingualChatRoom: 'Кімната багатомовного чату',
        denMarketplace: 'Ринок аеропорту DEN',
        qrCodeCreator: 'Глобальний ринок',
        ttyTtd: 'TTY/TDD призначено для послуг відеотрансляції для глухих і людей із вадами слуху',
        airlineAgents: 'Агенти авіакомпанії',
        personalCall: 'Особистий дзвінок',
        phoneNumber: 'Номер телефону',
        selectAirline: 'Виберіть свого агента авіакомпанії',
        airlineCalling: 'Дзвінок авіакомпанії',
        connect: 'Підключити',
    },
    eo: {
        selectLanguage: 'Bonvolu elekti vian lingvon',
        backMainMenu: 'Reen al Ĉefa Menuo',
        helpSubtitle: 'Kiel ni povas helpi vin hodiaŭ?',
        healthWellness: 'Kontrolo pri Sano kaj Bonstato',
        denSurvey: 'Enketo pri Klienta Servo de DEN',
        emergencyAccess: 'Servoj de Urĝa Aliro',
        multilingualCalling: 'Multlingva voko',
        freeCalling: 'Senpaga Voko',
        denLiveVideo: 'DEN Viva Videagento',
        multilingualChatRoom: 'Multlingva Babilejo',
        denMarketplace: 'Flughaveno Den',
        qrCodeCreator: 'Tutmonda Foirejo',
        ttyTtd: 'TTY/TDD estas por videoretransferservoj por surduloj kaj aŭdaj difektitaj',
        airlineAgents: 'Aviadkompaniaj agentoj',
        personalCall: 'Persona Voko',
        phoneNumber: 'Telefonnumero',
        selectAirline: 'Elektu vian flugkompanian agenton',
        airlineCalling: 'Aviadkompanio voko',
        connect: 'Konekti',
    },
    amharic: {
        selectLanguage: 'እባክዎ ቋንቋዎን ይምረጡ',
        backMainMenu: 'ወደ ዋና ምናሌ ተመለስ',
        helpSubtitle: 'ዛሬ እንዴት ልንረዳዎ እንችላለን?',
        healthWellness: 'የጤና እና የጤንነት ማረጋገጫ',
        denSurvey: 'DEN የደንበኞች አገልግሎት ዳሰሳ',
        emergencyAccess: 'የአደጋ ጊዜ መዳረሻ አገልግሎቶች',
        multilingualCalling: 'የብዙ ቋንቋ ጥሪ',
        freeCalling: 'ነጻ ጥሪ',
        denLiveVideo: 'DEN የቀጥታ ቪዲዮ ወኪል',
        multilingualChatRoom: 'የብዙ ቋንቋ ውይይት ክፍል',
        denMarketplace: 'DEN የአየር ማረፊያ የገበያ ቦታ',
        qrCodeCreator: 'ዓለም አቀፍ የገበያ ቦታ',
        ttyTtd: 'TTY/TDD መስማት ለተሳናቸው እና መስማት ለተሳናቸው የቪዲዮ ማስተላለፊያ አገልግሎት ነው',
        airlineAgents: 'የአየር መንገድ ወኪሎች',
        personalCall: 'የግል ጥሪ',
        phoneNumber: 'ስልክ ቁጥር',
        selectAirline: 'የአየር መንገድ ወኪልዎን ይምረጡ',
        airlineCalling: 'የአየር መንገድ ጥሪ',
        connect: 'አገናኝ',
    },
    javanese: {
        selectLanguage: 'Mangga pilih basa panjenengan',
        backMainMenu: 'Mbalik menyang Menu Utama',
        helpSubtitle: 'Kadospundi kita saged nulungi panjenengan dinten punika?',
        healthWellness: 'Pemeriksaan Kesehatan lan Kesejahteraan',
        denSurvey: 'Survei Layanan Pelanggan DEN',
        emergencyAccess: 'Layanan Akses Darurat',
        multilingualCalling: 'Panggilan multibasa',
        freeCalling: 'Nelpon Gratis',
        denLiveVideo: 'Den Urip Agen Video',
        multilingualChatRoom: 'Ruang Obrolan Multibahasa',
        denMarketplace: 'Pasar Bandara DEN',
        qrCodeCreator: 'Pasar Global',
        ttyTtd: 'TTY/TDD kanggo layanan relay video kanggo wong budheg lan gangguan pangrungu',
        airlineAgents: 'Agen maskapai',
        personalCall: 'Panggilan Pribadi',
        phoneNumber: 'Nomer Telpon',
        selectAirline: 'Pilih agen maskapai sampeyan',
        airlineCalling: 'Panggilan Maskapai',
        connect: 'Sambungake',
    },
    persian: {
        selectLanguage: 'لطفا زبان خود را انتخاب کنید',
        backMainMenu: 'بازگشت به منوی اصلی',
        helpSubtitle: 'چگونه می توانیم امروز به شما کمک کنیم؟',
        healthWellness: 'بررسی سلامت و تندرستی',
        denSurvey: 'بررسی خدمات مشتری DEN',
        emergencyAccess: 'خدمات دسترسی اضطراری',
        multilingualCalling: 'تماس چند زبانه',
        freeCalling: 'تماس رایگان',
        denLiveVideo: 'عامل ویدیو زنده',
        multilingualChatRoom: 'چت روم چند زبانه',
        denMarketplace: 'بازار فرودگاه',
        qrCodeCreator: 'بازار جهانی',
        ttyTtd: 'TTY/TDD برای خدمات رله ویدئویی برای ناشنوایان و ناشنوایان است',
        airlineAgents: 'نمایندگان هواپیمایی',
        personalCall: 'تماس شخصی',
        phoneNumber: 'شماره تلفن',
        selectAirline: 'نماینده هواپیمایی خود را انتخاب کنید',
        airlineCalling: 'تماس با شرکت هواپیمایی',
        connect: 'اتصال',
    },
    mk: {
        selectLanguage: 'Ве молиме изберете го вашиот јазик',
        backMainMenu: 'Назад во главното мени',
        helpSubtitle: 'Како можеме да ви помогнеме денес?',
        healthWellness: 'Проверка на здравјето и благосостојбата',
        denSurvey: 'Анкета за услуги на клиентите DEN',
        emergencyAccess: 'Услуги за итен пристап',
        multilingualCalling: 'Повеќејазично повикување',
        freeCalling: 'Бесплатен повик',
        denLiveVideo: 'Ден Во Живо Видео Агент',
        multilingualChatRoom: 'Повеќејазична соба за разговор',
        denMarketplace: 'ДЕН Аеродром Пазар',
        qrCodeCreator: 'Глобален пазар',
        ttyTtd: 'TTY/TDD е за услуги за видео реле за глуви и лица со оштетен слух',
        airlineAgents: 'Агенти на авиокомпании',
        personalCall: 'Личен повик',
        phoneNumber: 'Телефонски број',
        selectAirline: 'Изберете го вашиот авио агент',
        airlineCalling: 'Авиокомпанија Повик',
        connect: 'Поврзи',
    },
    kyrgyz: {
        selectLanguage: 'Тилиңизди тандаңыз',
        backMainMenu: 'Башкы менюга кайтуу',
        helpSubtitle: 'Биз бүгүн сизге кантип жардам бере алабыз?',
        healthWellness: 'Ден соолук жана сергектик текшерүү',
        denSurvey: 'DEN кардарларды тейлөө сурамжылоо',
        emergencyAccess: 'Тез жардам кызматтары',
        multilingualCalling: 'Көп тилдүү чакыруу',
        freeCalling: 'Акысыз чалуу',
        denLiveVideo: 'Түз эфирдеги видео агент',
        multilingualChatRoom: 'Көп тилдүү чат бөлмөсү',
        denMarketplace: 'Ден аэропортунун базары',
        qrCodeCreator: 'Глобалдык базар',
        ttyTtd: 'TTY/TDD дүлөйлөр жана угуусу начар адамдар үчүн видеорелейлик кызматтар үчүн',
        airlineAgents: 'Авиакомпаниянын агенттери',
        personalCall: 'Жеке чалуу',
        phoneNumber: 'Телефон номери',
        selectAirline: 'Авиакомпанияңыздын агентин тандаңыз',
        airlineCalling: 'Авиакомпанияга чакыруу',
        connect: 'Connect',
    },
    yiddish: {
        selectLanguage: 'ביטע אויסקלייַבן דיין שפּראַך',
        backMainMenu: 'צוריק צו הויפּט מעניו',
        helpSubtitle: 'ווי קענען מיר העלפֿן איר הייַנט?',
        healthWellness: 'געזונט און וועלנאַס טשעק',
        denSurvey: 'DEN קונה סערוויס יבערבליק',
        emergencyAccess: 'עמערגענסי אַקסעס סערוויסעס',
        multilingualCalling: 'מולטילינגואַל רופן',
        freeCalling: 'פריי רופן',
        denLiveVideo: 'DEN לייוו ווידעא אַגענט',
        multilingualChatRoom: 'מולטילינגוואַל שמועסן צימער',
        denMarketplace: 'DEN ערפּאָרט מאַרקעטפּלאַסע',
        qrCodeCreator: 'גלאבאלע מארקפלעצער',
        ttyTtd: 'TTY/TDD איז פֿאַר ווידעא רעלע סערוויסעס פֿאַר טויב און געהער ימפּערד',
        airlineAgents: 'ערליין אגענטן',
        personalCall: 'פערזענליכע רוף',
        phoneNumber: 'טעלעפֿאָן נומער',
        selectAirline: 'אויסקלײַבן דיין ערליין אַגענט',
        airlineCalling: 'ערליין רופן',
        connect: 'פאַרבינדן',
    },
    pap: {
        selectLanguage: 'Por fabor selecta bo idioma',
        backMainMenu: 'Bolbe na e menu prinsipal',
        helpSubtitle: 'Kon nos por yuda bo awe?',
        healthWellness: 'Check Di Salud y Bienestar',
        denSurvey: 'Den Client Service Survey',
        emergencyAccess: 'Servicio Di Acceso Di Emergencia',
        multilingualCalling: 'Llamada multilingüe',
        freeCalling: 'llamada gratis',
        denLiveVideo: 'Agente Di Video na BIDA den',
        multilingualChatRoom: 'Sala Di Chat Multilingüe',
        denMarketplace: 'Mercado di aeropuerto DEN',
        qrCodeCreator: 'Mercado global',
        ttyTtd: 'TTY / TDD ta pa servisionan di retransmishon di video pa hende surdu i hende ku un desabilidat di oido',
        airlineAgents: 'Agentenan di aerolinea',
        personalCall: 'Yamada personal',
        phoneNumber: 'Number di telefòn',
        selectAirline: 'Seleccioná bo agente di aerolinea',
        airlineCalling: 'Llamada di aerolinea',
        connect: 'Konektá',
    },
    be: {
        selectLanguage: 'Калі ласка, абярыце мову',
        backMainMenu: 'Назад у галоўнае меню',
        helpSubtitle: 'Чым мы можам дапамагчы вам сёння?',
        healthWellness: 'Праверка здароўя і дабрабыту',
        denSurvey: 'Апытанне абслугоўвання кліентаў DEN',
        emergencyAccess: 'Службы экстранага доступу',
        multilingualCalling: 'Шматмоўныя выклікі',
        freeCalling: 'Бясплатны званок',
        denLiveVideo: 'Відэаагент DEN Live',
        multilingualChatRoom: 'Шматмоўны чат',
        denMarketplace: 'Рынак аэрапорта DEN',
        qrCodeCreator: 'Глабальны рынак',
        ttyTtd: 'TTY/TDD прызначаны для паслуг відэатрансляцыі для глухіх і людзей са слабым слыхам',
        airlineAgents: 'Агенты авіякампаніі',
        personalCall: 'Асабісты званок',
        phoneNumber: 'Нумар тэлефона',
        selectAirline: 'Выберыце свайго агента авіякампаніі',
        airlineCalling: 'Выклік авіякампаніі',
        connect: 'Злучыцца',
    },
    'armenian-armenia': {
        selectLanguage: 'Խնդրում ենք ընտրել ձեր լեզուն',
        backMainMenu: 'Վերադառնալ հիմնական ընտրացանկ',
        helpSubtitle: 'Ինչպե՞ս կարող ենք օգնել քեզ այսօր',
        healthWellness: 'Առողջության և առողջության ստուգում',
        denSurvey: 'DEN Հաճախորդների սպասարկման հարցում',
        emergencyAccess: 'Արտակարգ իրավիճակների մուտքի ծառայություններ',
        multilingualCalling: 'Բազմալեզու զանգեր',
        freeCalling: 'Անվճար զանգ',
        denLiveVideo: 'Վիդեո գործակալ դեն Լիվե',
        multilingualChatRoom: 'Բազմալեզու Զրուցասենյակ',
        denMarketplace: 'Օդանավակայանի շուկա',
        qrCodeCreator: 'Գլոբալ շուկա',
        ttyTtd: 'TTY/TDD-ը նախատեսված է խուլերի և լսողության խնդիրներ ունեցողների տեսահաղորդման ծառայությունների համար',
        airlineAgents: 'Ավիաընկերության գործակալներ',
        personalCall: 'Անձնական զանգ',
        phoneNumber: 'Հեռախոսահամար',
        selectAirline: 'Ընտրեք ձեր ավիաընկերության գործակալը',
        airlineCalling: 'Զանգահարեք ավիաընկերությունից',
        connect: 'Միացնել',
    },
    malagasy: {
        selectLanguage: 'Mifidiana ny fiteninao azafady',
        backMainMenu: "Miverena any amin'ny Main Menu",
        helpSubtitle: 'Ahoana no azontsika anampiana anao anio?',
        healthWellness: 'Fandinihana ny fahasalamana sy ny fahasalamana',
        denSurvey: 'Fanadihadiana momba ny serivisy mpanjifa DEN',
        emergencyAccess: "Sampandraharahan'ny fidirana maika",
        multilingualCalling: "Fiantsoana amin'ny fiteny maro",
        freeCalling: 'Antso maimaim-poana',
        denLiveVideo: "Den Mpandraharaha Amin'ny Lahatsary Mivantana",
        multilingualChatRoom: "Efitrano firesahana amin'ny fiteny maro",
        denMarketplace: "Tsenan'ny seranam-piaramanidina DEN",
        qrCodeCreator: 'Tsena Maneran-tany',
        ttyTtd: "TTY/TDD dia natao ho an'ny serivisy fampitana horonan-tsary ho an'ny marenina sy sembana",
        airlineAgents: 'Mpanao zotram-piaramanidina',
        personalCall: 'Antso manokana',
        phoneNumber: 'Laharana finday',
        selectAirline: "Fidio ny mpiasan'ny zotram-piaramanidinao",
        airlineCalling: 'Fiantsoana fiaramanidina',
        connect: 'Mampifandray',
    },
    tajik: {
        selectLanguage: 'Лутфан забони худро интихоб кунед',
        backMainMenu: 'Бозгашт ба Менюи асосӣ',
        helpSubtitle: 'Чӣ гуна мо имрӯз ба шумо кӯмак карда метавонем?',
        healthWellness: 'Тафтиши саломатӣ ва некӯаҳволӣ',
        denSurvey: 'Тадқиқоти хидматрасонии муштариёни DEN',
        emergencyAccess: 'Хадамоти дастрасии изтирорӣ',
        multilingualCalling: 'Занги бисёрзабона',
        freeCalling: 'Занги ройгон',
        denLiveVideo: 'Агенти видеои DEN Live',
        multilingualChatRoom: 'Утоқи сӯҳбати бисёрзабона',
        denMarketplace: 'Бозори Фурудгоҳи DEN',
        qrCodeCreator: 'Бозори ҷаҳонӣ',
        ttyTtd: 'TTY/TDD барои хидматрасонии видеорелей барои кар ва шунавоӣ маҳдуд аст',
        airlineAgents: 'Агентҳои ҳавопаймоӣ',
        personalCall: 'Занги шахсӣ',
        phoneNumber: 'Рақами телефон',
        selectAirline: 'Агенти ширкати ҳавопаймоии худро интихоб кунед',
        airlineCalling: 'Занги ширкати ҳавопаймоӣ',
        connect: 'пайваст',
    },
    frisian: {
        selectLanguage: 'Selektearje asjebleaft jo taal',
        backMainMenu: 'Werom nei haadmenu',
        helpSubtitle: 'Hoe kinne wy jo hjoed helpe?',
        healthWellness: 'Sûnens- en wolwêzenskontrôle',
        denSurvey: 'DEN Customer Service Survey',
        emergencyAccess: 'Tsjinsten foar needtagong',
        multilingualCalling: 'Meartalige oprop',
        freeCalling: 'Fergees skilje',
        denLiveVideo: 'DEN Live persoan ',
        multilingualChatRoom: 'Meartalige petearkeamer',
        denMarketplace: 'DEN Airport Marketplace',
        qrCodeCreator: 'Wrâldmarktplaats',
        ttyTtd: 'TTY/TDD is foar fideorelaistsjinsten foar dôven en gehoar beheinden',
        airlineAgents: 'Airline aginten',
        personalCall: 'Persoanlike oprop',
        phoneNumber: 'Telefoonnûmer',
        selectAirline: 'Selektearje jo loftline-agint',
        airlineCalling: 'Airline ropt',
        connect: 'Ferbine',
    },
    af: {
        selectLanguage: 'Kies asseblief jou taal',
        backMainMenu: 'Terug na hoofkieslys',
        helpSubtitle: 'Hoe kan ons jou vandag help?',
        healthWellness: 'Gesondheid- en welstandkontrole',
        denSurvey: 'DEN Kliëntediensopname',
        emergencyAccess: 'Noodtoegangsdienste',
        multilingualCalling: 'Meertalige oproepe',
        freeCalling: 'Gratis oproepe',
        denLiveVideo: 'Lewende Agent',
        multilingualChatRoom: 'Meertalige kletskamer',
        denMarketplace: 'DEN Lughawe Mark',
        qrCodeCreator: 'Globale mark',
        ttyTtd: 'TTY/TDD is vir video-aflosdienste vir dowes en gehoorgestremdes',
        airlineAgents: 'Lugredery agente',
        personalCall: 'Persoonlike oproep',
        phoneNumber: 'Foonnommer',
        selectAirline: 'Kies jou lugredery agent',
        airlineCalling: 'Lugredery roep',
        connect: 'Verbind',
    },
    fr: {
        selectLanguage: 'Veuillez sélectionner votre langue',
        backMainMenu: 'Retour au menu principal',
        helpSubtitle: "Que peut-on faire pour vous aider aujourd'hui",
        healthWellness: 'Bilan de santé et de bien-être',
        denSurvey: 'Enquête sur le service client de DEN',
        emergencyAccess: "Services d'accès d'urgence",
        multilingualCalling: 'Appel multilingue',
        freeCalling: 'Appel gratuit',
        denLiveVideo: 'Agent vidéo en direct DEN',
        multilingualChatRoom: 'Salon de discussion multilingue',
        denMarketplace: "Marché de l'aéroport DEN",
        qrCodeCreator: 'Marché mondial',
        ttyTtd: 'TTY/TDD est destiné aux services de relais vidéo pour les sourds et les malentendants',
        airlineAgents: 'Agents aériens',
        personalCall: 'Appel personnel',
        phoneNumber: 'Numéro de téléphone',
        selectAirline: 'Sélectionnez votre agent aérien',
        airlineCalling: 'Appel de la compagnie aérienne',
        connect: 'Connecter',
    },
    tahitian: {
        selectLanguage: "A ma'iti i ta outou",
        backMainMenu: "Ho'i faahou i nia i te tabula rahi",
        helpSubtitle: "Nahea e ti'a ai ia matou ia tauturu ia outou i teie mahana ?",
        healthWellness: 'Te oraora - maitai - raa e te vai - maitai - raa',
        denSurvey: 'Te hoê titorotororaa no nia i te taviniraa i Denver',
        emergencyAccess: 'Te mau pû tauturu rû',
        multilingualCalling: 'Piiraa rau',
        freeCalling: 'Piiraa Tamoni ore',
        denLiveVideo: "Te mau hoho'a video ti'a no Denver",
        multilingualChatRoom: 'Piha o te reo e rave rahi',
        denMarketplace: 'Te matete o te tauraa manureva no Denver',
        qrCodeCreator: 'Matete na te ao nei',
        ttyTtd: "Te TTY e te TTD, no te mau hoho'a video ïa no te feia tari'a turi e te feia tari'a turi.",
        airlineAgents: 'Te mau manu reva',
        personalCall: 'Piiraa o te taata iho',
        phoneNumber: 'Numera niuniu',
        selectAirline: "A ma'iti i to outou ti'a faatere no te manureva",
        airlineCalling: 'Te piiraa o te manureva',
        connect: "Tu'ati",
    },
    finnish: {
        selectLanguage: 'Valitse kieli',
        backMainMenu: 'Takaisin päävalikkoon',
        helpSubtitle: 'kuinka voimme auttaa sinua tänään?',
        healthWellness: 'Terveys- ja hyvinvointitarkastus',
        denSurvey: 'DEN-asiakaspalvelukysely',
        emergencyAccess: 'Hätäpalvelut',
        multilingualCalling: 'Monikielinen soitto',
        freeCalling: 'Ilmainen puhelu',
        denLiveVideo: 'DEN Live-videoagentti',
        multilingualChatRoom: 'Monikielinen chat-huone',
        denMarketplace: 'Denverin lentoaseman tori',
        qrCodeCreator: 'Globaali markkinapaikka',
        ttyTtd: 'TTY/TDD on tarkoitettu kuurojen ja kuulovammaisten videovälityspalveluihin',
        airlineAgents: 'Lentoyhtiöiden edustajat',
        personalCall: 'Henkilökohtainen soitto',
        phoneNumber: 'Puhelinnumero',
        selectAirline: 'Valitse lentoyhtiösi edustaja',
        airlineCalling: 'Lentoyhtiön soitto',
        connect: 'Kytkeä',
    },
    corsican: {
        selectLanguage: 'Per piacè selezziunà a to lingua',
        backMainMenu: 'Torna à u menu principale',
        helpSubtitle: 'Cumu pudemu aiutà vi oghje?',
        healthWellness: 'Controllà a salute è u benessere',
        denSurvey: 'Indagine di serviziu à i clienti DEN',
        emergencyAccess: "Servizi d'accessu d'urgenza",
        multilingualCalling: 'Chiamata multilingua',
        freeCalling: 'Chiamata gratisi',
        denLiveVideo: 'Agente Video DEN Live',
        multilingualChatRoom: 'Stanza di chat multilingua',
        denMarketplace: "Mercattu di l'aeroportu DEN",
        qrCodeCreator: 'Mercattu glubale',
        ttyTtd: 'TTY/TDD hè per i servizii di video relay per i sordi è i sordi',
        airlineAgents: 'Agenti aerei',
        personalCall: 'Chiamata persunale',
        phoneNumber: 'Numeru telefuninu',
        selectAirline: 'Selezziunà u to agentu aereo',
        airlineCalling: 'Chiamata aerea',
        connect: 'Connectà',
    },
    ig: {
        selectLanguage: 'Biko họrọ asụsụ gị',
        backMainMenu: 'Laghachi na Isi NchNhr',
        helpSubtitle: 'Olee otu anyị ga-esi nyere gị aka taa?',
        healthWellness: 'Nlele ahụike na ahụike',
        denSurvey: 'Nnyocha ọrụ ndị ahịa DEN',
        emergencyAccess: 'Ọrụ nnweta mberede',
        multilingualCalling: 'Oku ọtụtụ asụsụ',
        freeCalling: 'Oku efu',
        denLiveVideo: 'DNa-ebi Ndụ Na Mmadụ',
        multilingualChatRoom: 'Ụlọ nkata ọtụtụ asụsụ',
        denMarketplace: 'Ọmaahịa ọdụ ụgbọ elu DEN',
        qrCodeCreator: 'Ebe Azụmahịa zuru ụwa ọnụ',
        ttyTtd: 'TTY/TDD bụ maka ọrụ nnyefe vidiyo maka ndị ntị chiri na ndị ntị chiri',
        airlineAgents: 'Ndị ọrụ ụgbọ elu',
        personalCall: 'Oku nkeonwe',
        phoneNumber: 'nọmba ekwentị',
        selectAirline: 'Họrọ onye nnọchi anya ụgbọ elu gị',
        airlineCalling: 'Ọkpọ ụgbọ elu',
        connect: 'Jikọọ',
    },
    latin: {
        selectLanguage: 'Placere linguam tuam eligere',
        backMainMenu: 'si torna A Menu',
        HelpSubtitle: 'Quomodo te hodie adiuvare possumus?',
        healthWellness: 'Salus et Welness Reprehendo',
        denSurvey: 'Den Mos Muneris Survey',
        emergencyAccess: 'Subitis Accessum Officia',
        multilingualCalling: 'Vocatio Multilingual',
        freeCalling: 'Liberum Vocatio',
        denLiveVideo: 'DEN Vive Agens Video',
        multilingualChatRoom: 'Multilingual Chat Locus',
        denMarketplace: 'DEN Aeroportus Marketplace',
        qrCodeCreator: 'Fori Global',
        ttyTtd: 'TTY/TDD est ut operas nullas video surdis et auditus imminutas',
        airlineAgents: 'Agentia porttitor',
        personalCall: 'personalis Call',
        phoneNumber: 'Numerus Telephonicus',
        selectAirline: 'Elige tuum porttitor agente',
        airlineCalling: 'porttitor vocatio',
        connect: 'coniungere',
    },
    swedish: {
        selectLanguage: 'Välj ditt språk',
        backMainMenu: 'Tillbaka till huvudmenyn',
        helpSubtitle: 'Hur kan vi hjälpa dig idag?',
        healthWellness: 'Hälso- och välbefinnandekontroll',
        denSurvey: 'DEN kundtjänstundersökning',
        emergencyAccess: 'Nödåtkomsttjänster',
        multilingualCalling: 'Flerspråkigt samtal',
        freeCalling: 'Gratis samtal',
        denLiveVideo: 'DBo personligen',
        multilingualChatRoom: 'Flerspråkigt chattrum',
        denMarketplace: 'Marknadsplats på flygplatsen',
        qrCodeCreator: 'Global marknadsplats',
        ttyTtd: 'TTY/TDD är för videorelätjänster för döva och hörselskadade',
        airlineAgents: 'Flygbolagsagenter',
        personalCall: 'Personligt samtal',
        phoneNumber: 'Telefonnummer',
        selectAirline: 'Välj ditt flygbolagsagent',
        airlineCalling: 'Flygbolaget ringer',
        connect: 'Ansluta',
    },
    romanian: {
        selectLanguage: 'Vă rugăm să selectați limba dvs.',
        backMainMenu: 'Înapoi la meniul principal',
        helpSubtitle: 'Cum vă putem ajuta astăzi?',
        healthWellness: 'Verificarea sănătății și a bunăstării',
        denSurvey: 'Sondaj DEN privind serviciile clienților',
        emergencyAccess: 'Servicii de acces de urgență',
        multilingualCalling: 'Apel multilingv',
        freeCalling: 'Apel gratuit',
        denLiveVideo: 'Trăiește în persoană',
        multilingualChatRoom: 'Cameră de chat multilingvă',
        denMarketplace: 'DEN Piața aeroportului',
        qrCodeCreator: 'Piața globală',
        ttyTtd: 'TTY/TDD este pentru servicii de releu video pentru surzi și cu deficiențe de auz',
        airlineAgents: 'Agenți ai companiilor aeriene',
        personalCall: 'Apel personal',
        phoneNumber: 'Număr de telefon',
        selectAirline: 'Selectați agentul companiei aeriene',
        airlineCalling: 'Apelul companiei aeriene',
        connect: 'Conectează-te',
    },
    kannada: {
        selectLanguage: 'ದಯವಿಟ್ಟು ನಿಮ್ಮ ಭಾಷೆಯನ್ನು ಆಯ್ಕೆ ಮಾಡಿ',
        backMainMenu: 'ಮುಖ್ಯ ಮೆನುಗೆ ಹಿಂತಿರುಗಿ',
        helpSubtitle: 'ನಾವು ಇಂದು ನಿಮಗೆ ಹೇಗೆ ಸಹಾಯ ಮಾಡಬಹುದು?',
        healthWellness: 'ಆರೋಗ್ಯ ಮತ್ತು ಕ್ಷೇಮ ತಪಾಸಣೆ',
        denSurvey: 'DEN ಗ್ರಾಹಕ ಸೇವಾ ಸಮೀಕ್ಷೆ',
        emergencyAccess: 'ತುರ್ತು ಪ್ರವೇಶ ಸೇವೆಗಳು',
        multilingualCalling: 'ಬಹುಭಾಷಾ ಕರೆ',
        freeCalling: 'ಉಚಿತ ಕರೆ',
        denLiveVideo: 'DEN ಲೈವ್ ವೀಡಿಯೊ ಏಜೆಂಟ್',
        multilingualChatRoom: 'ಬಹುಭಾಷಾ ಚಾಟ್ ರೂಮ್',
        denMarketplace: 'DEN ವಿಮಾನ ನಿಲ್ದಾಣ ಮಾರುಕಟ್ಟೆ',
        qrCodeCreator: 'ಜಾಗತಿಕ ಮಾರುಕಟ್ಟೆ',
        ttyTtd: 'TTY/TDD ಕಿವುಡ ಮತ್ತು ಶ್ರವಣದೋಷವುಳ್ಳವರಿಗೆ ವೀಡಿಯೊ ರಿಲೇ ಸೇವೆಗಳಿಗಾಗಿ',
        airlineAgents: 'ಏರ್ಲೈನ್ ಏಜೆಂಟ್ಸ್',
        personalCall: 'ವೈಯಕ್ತಿಕ ಕರೆ',
        phoneNumber: 'ಫೋನ್ ಸಂಖ್ಯೆ',
        selectAirline: 'ನಿಮ್ಮ ಏರ್ಲೈನ್ ಏಜೆಂಟ್ ಅನ್ನು ಆಯ್ಕೆ ಮಾಡಿ',
        airlineCalling: 'ಏರ್ಲೈನ್ ಕರೆ',
        connect: 'ಸಂಪರ್ಕ',
    },
    it: {
        selectLanguage: 'Seleziona la tua lingua',
        backMainMenu: 'Indietro al menù principale',
        helpSubtitle: 'Come possiamo aiutarvi oggi?',
        healthWellness: 'Controllo della salute e del benessere',
        denSurvey: 'Sondaggio sul servizio clienti DEN',
        emergencyAccess: 'Servizi di accesso di emergenza',
        multilingualCalling: 'Chiamate multilingue',
        freeCalling: 'Chiamate gratuite',
        denLiveVideo: 'DEN Agente video in diretta',
        multilingualChatRoom: 'Chat room multilingue',
        denMarketplace: "Mercato dell'aeroporto DEN",
        qrCodeCreator: 'Mercato globale',
        ttyTtd: 'TTY/TDD è per i servizi di ritrasmissione video per non udenti e ipoudenti',
        airlineAgents: 'Agenti aerei',
        personalCall: 'Chiamata personale',
        phoneNumber: 'Numero di telefono',
        selectAirline: 'Seleziona il tuo agente aereo',
        airlineCalling: 'Chiamate aeree',
        connect: 'Collegare',
    },
    uzbek: {
        selectLanguage: 'Tilingizni tanlang',
        backMainMenu: 'Asosiy menyuga qaytish',
        helpSubtitle: 'Bugun sizga qanday yordam bera olamiz?',
        healthWellness: 'Salomatlik va farovonlikni tekshirish',
        denSurvey: "DEN mijozlarga xizmat ko'rsatish so'rovi",
        emergencyAccess: 'Favqulodda kirish xizmatlari',
        multilingualCalling: "Ko'p tilli qo'ng'iroq",
        freeCalling: "Bepul qo'ng'iroq",
        denLiveVideo: 'DEN Shaxs agentida yashang',
        multilingualChatRoom: "Ko'p tilli suhbat xonasi",
        denMarketplace: 'DEN aeroporti bozori',
        qrCodeCreator: 'Global bozor',
        ttyTtd: 'TTY/TDD kar va eshitish qobiliyati zaiflar uchun video reley xizmatlari uchun',
        airlineAgents: 'Aviakompaniya agentlari',
        personalCall: "Shaxsiy qo'ng'iroq",
        phoneNumber: 'Telefon raqami',
        selectAirline: "O'z aviakompaniya agentingizni tanlang",
        airlineCalling: "Aviakompaniyaga qo'ng'iroq qilish",
        connect: 'Ulanish',
    },
    lao: {
        selectLanguage: 'ກະລຸນາເລືອກພາສາຂອງເຈົ້າ',
        backMainMenu: 'ກັບໄປທີ່ເມນູຫຼັກ',
        helpSubtitle: 'ມື້ນີ້ເຮົາຊ່ວຍເຈົ້າໄດ້ແນວໃດ?',
        healthWellness: 'ກວດສຸຂະພາບແລະສຸຂະພາບ',
        denSurvey: 'ການສຳຫຼວດບໍລິການລູກຄ້າ DEN',
        emergencyAccess: 'ບໍລິການເຂົ້າເຖິງສຸກເສີນ',
        multilingualCalling: 'ການໂທຫຼາຍພາສາ',
        freeCalling: 'ໂທຟຣີ',
        denLiveVideo: 'DEN ຕົວແທນສົດ',
        multilingualChatRoom: 'ຫ້ອງສົນທະນາຫຼາຍພາສາ',
        denMarketplace: 'ຕະຫຼາດສະໜາມບິນ DEN',
        qrCodeCreator: 'ຕະຫຼາດທົ່ວໂລກ',
        ttyTtd: 'TTY/TDD ແມ່ນສຳລັບການບໍລິການຖ່າຍທອດວິດີໂອສຳລັບຄົນຫູໜວກ ແລະພິການຫູ',
        airlineAgents: 'ຕົວແທນສາຍການບິນ',
        personalCall: 'ໂທສ່ວນຕົວ',
        phoneNumber: 'ເບີໂທລະສັບ',
        selectAirline: 'ເລືອກຕົວແທນສາຍການບິນຂອງເຈົ້າ',
        airlineCalling: 'ສາຍການບິນ',
        connect: 'ເຊື່ອມຕໍ່',
    },
    tatar: {
        selectLanguage: 'Зинһар, телегезне сайлагыз',
        backMainMenu: 'Төп менюга кире кайту',
        helpSubtitle: 'Бүген без сезгә ничек ярдәм итә алабыз?',
        healthWellness: 'Сәламәтлек һәм сәламәтлекне тикшерү',
        denSurvey: 'DEN клиентларга хезмәт күрсәтү',
        emergencyAccess: 'Ашыгыч ярдәм хезмәте',
        multilingualCalling: 'Күп телләрдә шалтырату',
        freeCalling: 'Ирекле шалтырату',
        denLiveVideo: 'DEN Тере агент',
        multilingualChatRoom: 'Күп телләрле чат бүлмәсе',
        denMarketplace: 'DEN аэропорты базары',
        qrCodeCreator: 'Глобаль базар',
        ttyTtd: 'TTY / TDD саңгырау һәм ишетү начар кешеләр өчен видео эстафета хезмәтләре өчен',
        airlineAgents: 'Авиакомпания агентлары',
        personalCall: 'Шәхси шалтырату',
        phoneNumber: 'Телефон номеры',
        selectAirline: 'Авиакомпания агентын сайлагыз',
        airlineCalling: 'Авиакомпания шалтыраты',
        connect: 'тоташу',
    },
    marathi: {
        selectLanguage: 'कृपया तुमची भाषा निवडा',
        backMainMenu: 'मुख्य मेनूवर परत',
        helpSubtitle: 'आज आम्ही तुम्हाला कशी मदत करू शकतो?',
        healthWellness: 'आरोग्य आणि कल्याण तपासणी',
        denSurvey: 'DEN ग्राहक सेवा सर्वेक्षण',
        emergencyAccess: 'आपत्कालीन प्रवेश सेवा',
        multilingualCalling: 'बहुभाषिक कॉलिंग',
        freeCalling: 'विनामूल्य कॉलिंग',
        denLiveVideo: 'DEN लाइव्ह व्हिडिओ एजंट',
        multilingualChatRoom: 'बहुभाषिक चॅट रूम',
        denMarketplace: 'DEN विमानतळ मार्केटप्लेस',
        qrCodeCreator: 'जागतिक बाजारपेठ',
        ttyTtd: 'टीटीवाय/टीडीडी हे कर्णबधिर आणि श्रवणदोष असलेल्यांसाठी व्हिडिओ रिले सेवांसाठी आहे',
        airlineAgents: 'एअरलाइन एजंट',
        personalCall: 'वैयक्तिक कॉल',
        phoneNumber: 'फोन नंबर',
        selectAirline: 'तुमचा एअरलाइन एजंट निवडा',
        airlineCalling: 'एअरलाइन कॉलिंग',
        connect: 'कनेक्ट करा',
    },
    turkmen: {
        selectLanguage: 'Diliňizi saýlaň',
        backMainMenu: 'Esasy menýu yzyna dolan',
        helpSubtitle: 'Bu gün size nädip kömek edip bileris?',
        healthWellness: 'Saglyk we saglygy barlamak',
        denSurvey: 'DEN Müşderi Hyzmaty',
        emergencyAccess: 'Gyssagly kömek hyzmatlary',
        multilingualCalling: 'Köp dilli jaň',
        freeCalling: 'Mugt jaň',
        denLiveVideo: 'DEN göni wideo agenti',
        multilingualChatRoom: 'Köp dilli söhbetdeşlik otagy',
        denMarketplace: 'DEN howa menzili bazary',
        qrCodeCreator: 'Dünýä bazary',
        ttyTtd: 'TTY / TDD kerler we eşidýänler üçin wideo relay hyzmatlary üçin',
        airlineAgents: 'Howa ýollarynyň wekilleri',
        personalCall: 'Şahsy jaň',
        phoneNumber: 'awiakompaniýanyň wekili',
        selectAirline: 'Awiakompaniýanyň wekilini saýlaň',
        airlineCalling: 'awiakompaniýa jaň edýär',
        connect: 'Birikdir',
    },
    hausa: {
        selectLanguage: 'Da fatan za a zaɓi yaren ku',
        backMainMenu: 'Komawa ga Babban Menu',
        helpSubtitle: 'Ta yaya za mu iya taimaka muku a yau?',
        healthWellness: 'Binciken Lafiya da Lafiya',
        denSurvey: 'DEN Binciken Sabis na Abokin Ciniki',
        emergencyAccess: 'Sabis na Samun Gaggawa',
        multilingualCalling: 'Kira na harsuna da yawa',
        freeCalling: 'Kira Kyauta',
        denLiveVideo: 'Wakilin Bidiyo Live DEN',
        multilingualChatRoom: 'Dakin Tattaunawar Harsuna',
        denMarketplace: 'Kasuwar tashar jirgin sama DEN',
        qrCodeCreator: 'Kasuwancin Duniya',
        ttyTtd: 'TTY/TDD na sabis ne na watsa bidiyo ga kurame da nakasassu',
        airlineAgents: "Ma'aikatan jirgin sama",
        personalCall: 'Kira Na Kashi',
        phoneNumber: 'Lambar waya',
        selectAirline: 'Zaɓi wakilin jirgin ku',
        airlineCalling: 'Kiran Jirgin Sama',
        connect: 'Haɗa',
    },
    japanese: {
        selectLanguage: '言語を選択してください',
        backMainMenu: 'メインメニューに戻る',
        helpSubtitle: '今日はどのようにお手伝いできるでしょうか?',
        healthWellness: '健康診断',
        denSurvey: 'DEN カスタマーサービスアンケート',
        emergencyAccess: '緊急アクセスサービス',
        multilingualCalling: '多言語通話',
        freeCalling: '無料通話',
        denLiveVideo: 'DEN ライブビデオエージェント',
        multilingualChatRoom: '多言語チャットルーム',
        denMarketplace: 'DEN 空港マーケットプレイス',
        qrCodeCreator: 'グローバル マーケットプレイス',
        ttyTtd: 'TTY/TDD は聴覚障害者向けのビデオ中継サービスです。',
        airlineAgents: '航空会社の代理店',
        personalCall: '個人通話',
        phoneNumber: '電話番号',
        selectAirline: '航空会社の代理店を選択してください',
        airlineCalling: '航空会社への電話',
        connect: '接続する',
    },
    hu: {
        selectLanguage: 'Kérjük, válassza ki a nyelvet',
        backMainMenu: 'Vissza a főmenübe',
        helpSubtitle: 'Hogyan tudunk segíteni ma?',
        healthWellness: 'Egészség és Wellness ellenőrzés',
        denSurvey: 'DEN Ügyfélszolgálati Felmérés',
        emergencyAccess: 'Vészhelyzeti hozzáférési szolgáltatások',
        multilingualCalling: 'Többnyelvű hívás',
        freeCalling: 'Ingyenes hívás',
        denLiveVideo: 'DEN Élő videó ügynök',
        multilingualChatRoom: 'Többnyelvű csevegőszoba',
        denMarketplace: 'DEN repülőtéri piactér',
        qrCodeCreator: 'Globális piactér',
        ttyTtd: 'A TTY/TDD a siketek és hallássérültek számára nyújtott videoközvetítési szolgáltatásokra szolgál',
        airlineAgents: 'Légitársaság ügynökei',
        personalCall: 'Személyes hívás',
        phoneNumber: 'Telefonszám',
        selectAirline: 'Válassza ki légitársaság ügynökét',
        airlineCalling: 'Légitársaság hívása',
        connect: 'Csatlakozás',
    },
    polish: {
        selectLanguage: 'Wybierz swój język',
        backMainMenu: 'Powrót do menu głównego',
        helpSubtitle: 'Jak możemy ci dzisiaj pomóc?',
        healthWellness: 'Kontrola zdrowia i dobrego samopoczucia',
        denSurvey: 'Ankieta dotycząca obsługi klienta DEN',
        emergencyAccess: 'Usługi dostępu awaryjnego',
        multilingualCalling: 'Połączenia wielojęzyczne',
        freeCalling: 'Bezpłatne połączenia',
        denLiveVideo: 'DEN Agent wideo na żywo',
        multilingualChatRoom: 'Wielojęzyczny pokój rozmów',
        denMarketplace: 'Giełda lotniska DEN',
        qrCodeCreator: 'Globalny rynek',
        ttyTtd: 'TTY/TDD służy do świadczenia usług transmisji wideo dla osób niesłyszących i niedosłyszących',
        airlineAgents: 'Agenci linii lotniczych',
        personalCall: 'Rozmowa osobista',
        phoneNumber: 'Numer telefonu',
        selectAirline: 'Wybierz agenta linii lotniczych',
        airlineCalling: 'Rozmowa z linią lotniczą',
        connect: 'Łączyć',
    },
    hmong: {
        selectLanguage: 'Thov xaiv koj hom lus',
        backMainMenu: 'Rov qab mus rau Main Menu',
        helpSubtitle: 'Peb tuaj yeem pab koj li cas hnub no?',
        healthWellness: 'Kev Noj Qab Haus Huv thiab Kev Nyab Xeeb',
        denSurvey: 'DEN Kev Tshawb Fawb Cov Neeg Siv Khoom',
        emergencyAccess: 'Kev Pab Thaum Muaj Xwm Ceev',
        multilingualCalling: 'Multilingual hu',
        freeCalling: 'Kev Hu Dawb',
        denLiveVideo: 'DEN Yees duab tus neeg saib xyuas',
        multilingualChatRoom: 'Multilingual Chat Chav tsev',
        denMarketplace: 'DEN tshav dav hlau Marketplace',
        qrCodeCreator: 'Ntiaj teb no marketplace',
        ttyTtd: 'TTY/TDD yog rau kev pab cuam video relay rau cov lag ntseg thiab tsis hnov lus',
        airlineAgents: 'Menyuam noj cov menyuam noj',
        personalCall: 'Tus Kheej Hu',
        phoneNumber: 'Xov tooj',
        selectAirline: 'Xaiv koj lub dav hlau tus neeg sawv cev',
        airlineCalling: 'Airline Hu rau',
        connect: 'Txuas',
    },
    georgian: {
        selectLanguage: 'გთხოვთ აირჩიოთ თქვენი ენა',
        backMainMenu: 'უკან მთავარ მენიუში',
        helpSubtitle: 'როგორ შეგვიძლია დაგეხმაროთ დღეს?',
        healthWellness: 'ჯანმრთელობისა და კეთილდღეობის შემოწმება',
        denSurvey: 'DEN მომხმარებელთა მომსახურების კვლევა',
        emergencyAccess: 'გადაუდებელი დახმარების სერვისები',
        multilingualCalling: 'მრავალენოვანი ზარი',
        freeCalling: 'უფასო ზარი',
        denLiveVideo: 'DEN ცოცხალი ვიდეო აგენტი',
        multilingualChatRoom: 'მრავალენოვანი ჩატის ოთახი',
        denMarketplace: 'DEN აეროპორტის ბაზარი',
        qrCodeCreator: 'გლობალური ბაზარი',
        ttyTtd: 'TTY/TDD არის ყრუ და სმენადაქვეითებული ადამიანებისთვის ვიდეო სარელეო სერვისებისთვის',
        airlineAgents: 'ავიაკომპანიის აგენტები',
        personalCall: 'პერსონალური ზარი',
        phoneNumber: 'ტელეფონის ნომერი',
        selectAirline: 'აირჩიე შენი ავიაკომპანიის აგენტი',
        airlineCalling: 'ავიაკომპანიის დარეკვა',
        connect: 'დაკავშირება',
    },
    malay: {
        selectLanguage: 'Sila pilih bahasa anda',
        backMainMenu: 'Kembali ke Menu Utama',
        helpSubtitle: 'Bagaimana kami boleh membantu anda hari ini?',
        healthWellness: 'Pemeriksaan Kesihatan dan Kesejahteraan',
        denSurvey: 'Tinjauan Perkhidmatan Pelanggan DEN',
        emergencyAccess: 'Perkhidmatan Akses Kecemasan',
        multilingualCalling: 'Panggilan berbilang bahasa',
        freeCalling: 'Panggilan Percuma',
        denLiveVideo: 'Ejen Video Langsung DEN',
        multilingualChatRoom: 'Bilik Sembang Pelbagai bahasa',
        denMarketplace: 'DEN Marketplace Lapangan Terbang',
        qrCodeCreator: 'Pasaran Global',
        ttyTtd: 'TTY/TDD adalah untuk perkhidmatan penyampaian video untuk orang pekak dan bermasalah pendengaran',
        airlineAgents: 'Ejen syarikat penerbangan',
        personalCall: 'Panggilan Peribadi',
        phoneNumber: 'Nombor Telefon',
        selectAirline: 'Pilih ejen syarikat penerbangan anda',
        airlineCalling: 'Panggilan Syarikat Penerbangan',
        connect: 'Connect',
    },

    'haitian-creole': {
        selectLanguage: 'Tanpri chwazi lang ou',
        backMainMenu: 'Tounen nan meni prensipal',
        helpSubtitle: 'Kijan nou ka ede w jodi a?',
        healthWellness: 'Tcheke sante ak byennèt',
        denSurvey: 'Sondaj sèvis Kliyantèl DEN',
        emergencyAccess: 'Sèvis Aksè pou Ijans',
        multilingualCalling: 'Rele plizyè lang',
        freeCalling: 'Rele gratis',
        denLiveVideo: 'Ajan Videyo DEN Live',
        multilingualChatRoom: 'Sal chat plizyè lang',
        denMarketplace: 'DEN Mache Ayewopò',
        qrCodeCreator: 'Mache mondyal',
        ttyTtd: 'TTY/TDD se pou sèvis relè videyo pou moun ki soud ak moun ki pa tande byen',
        airlineAgents: 'Ajan avyon',
        personalCall: 'Apel pèsonèl',
        phoneNumber: 'Nimewo Telefòn',
        selectAirline: 'Chwazi ajan avyon w',
        airlineCalling: 'Airline Rele',
        connect: 'Konekte',
    },
    sw: {
        selectLanguage: 'Tafadhali chagua lugha yako',
        backMainMenu: 'Nyuma kwenye Menyu Kuu',
        helpSubtitle: 'Tunawezaje kukusaidia leo?',
        healthWellness: 'Ukaguzi wa Afya na Ustawi',
        denSurvey: 'Utafiti wa Huduma kwa Wateja',
        emergencyAccess: 'Huduma za Ufikiaji wa Dharura',
        multilingualCalling: 'Wito wa lugha nyingi',
        freeCalling: 'Kupiga simu kwa bure',
        denLiveVideo: 'Wakala wa Video wa Moja kwa Moja',
        multilingualChatRoom: 'Chumba cha Gumzo cha Lugha nyingi',
        denMarketplace: 'Soko la Uwanja wa Ndege wa DEN',
        qrCodeCreator: 'Soko la Ulimwenguni',
        ttyTtd: 'TTY/TDD ni kwa ajili ya huduma za kupeleka video kwa viziwi na viziwi',
        airlineAgents: 'Wakala wa Ndege',
        personalCall: 'Piga simu ya kibinafsi',
        phoneNumber: 'Namba ya simu',
        selectAirline: 'Chagua wakala wako wa ndege',
        airlineCalling: 'Piga simu',
        connect: 'Pamoja',
    },
    greek: {
        selectLanguage: 'Επιλέξτε τη γλώσσα σας',
        backMainMenu: 'Επιστροφή στο Κύριο Μενού',
        helpSubtitle: 'Πώς μπορούμε να σας βοηθήσουμε σήμερα;',
        healthWellness: 'Έλεγχος υγείας και ευεξίας',
        denSurvey: 'Έρευνα Εξυπηρέτησης Πελατών DEN',
        emergencyAccess: 'Υπηρεσίες πρόσβασης έκτακτης ανάγκης',
        multilingualCalling: 'Πολύγλωσση κλήση',
        freeCalling: 'Δωρεάν Κλήση',
        denLiveVideo: 'DEN Πράκτορας ζωντανού βίντεο',
        multilingualChatRoom: 'Πολύγλωσσο Chat Room',
        denMarketplace: 'DEN Αγορά αεροδρομίου',
        qrCodeCreator: 'Παγκόσμια Αγορά',
        ttyTtd: 'Το TTY/TDD είναι για υπηρεσίες αναμετάδοσης βίντεο για κωφούς και άτομα με προβλήματα ακοής',
        airlineAgents: 'Πράκτορες αεροπορικών εταιρειών',
        personalCall: 'Προσωπική κλήση',
        phoneNumber: 'Τηλεφωνικό νούμερο',
        selectAirline: 'Επιλέξτε τον πράκτορα της αεροπορικής σας εταιρείας',
        airlineCalling: 'Κλήση αεροπορικής εταιρείας',
        connect: 'Συνδέω-συωδεομαι',
    },
    khmer: {
        selectLanguage: 'សូមជ្រើសរើសភាសារបស់អ្នក',
        backMainMenu: 'ត្រលប់ទៅម៉ឺនុយមេ',
        helpSubtitle: 'តើយើងអាចជួយអ្នកថ្ងៃនេះដោយរបៀបណា?',
        healthWellness: 'ការពិនិត្យសុខភាព និងសុខភាព',
        denSurvey: 'ការស្ទង់មតិសេវាកម្មអតិថិជនរបស់ DEN',
        emergencyAccess: 'សេវាចូលសង្គ្រោះបន្ទាន់',
        multilingualCalling: 'ការហៅច្រើនភាសា',
        freeCalling: 'ការហៅទូរសព្ទដោយឥតគិតថ្លៃ',
        denLiveVideo: 'ភ្នាក់ងារ វីដេអូ ដេន',
        multilingualChatRoom: 'បន្ទប់ជជែកពហុភាសា',
        denMarketplace: 'ផ្សារព្រលានយន្តហោះ DEN',
        qrCodeCreator: 'ទីផ្សារសកល',
        ttyTtd: 'TTY/TDD គឺសម្រាប់សេវាបញ្ជូនតវីដេអូសម្រាប់អ្នកថ្លង់ និងពិការការស្តាប់',
        airlineAgents: 'ភ្នាក់ងារអាកាសចរណ៍',
        personalCall: 'ការហៅផ្ទាល់ខ្លួន',
        phoneNumber: 'លេខទូរស័ព្ទ',
        selectAirline: 'ជ្រើសរើសភ្នាក់ងារអាកាសចរណ៍របស់អ្នក',
        airlineCalling: 'ការហៅតាមយន្តហោះ',
        connect: 'ភ្ជាប់',
    },
    hindi: {
        selectLanguage: 'कृपया अपनी भाषा चुनें',
        backMainMenu: 'मुख्य मेनू पर वापस जाएँ',
        helpSubtitle: 'आज हम आपकी कैसे मदद कर सकते हैं?',
        healthWellness: 'स्वास्थ्य और कल्याण जाँच',
        denSurvey: 'DEN ग्राहक सेवा सर्वेक्षण',
        emergencyAccess: 'आपातकालीन पहुँच सेवाएँ',
        multilingualCalling: 'बहुभाषी कॉलिंग',
        freeCalling: 'फ्री कॉलिंग',
        denLiveVideo: 'डेन लाइव वीडियो एजेंट',
        multilingualChatRoom: 'बहुभाषी चैट रूम',
        denMarketplace: 'डेन एयरपोर्ट मार्केटप्लेस',
        qrCodeCreator: 'वैश्विक बाज़ार',
        ttyTtd: 'TTY/TDD बधिरों और श्रवण बाधितों के लिए वीडियो रिले सेवाओं के लिए है',
        airlineAgents: 'एयरलाइन एजेंट्स',
        personalCall: 'व्यक्तिगत कॉल',
        phoneNumber: 'फ़ोन नंबर',
        selectAirline: 'अपना एयरलाइन एजेंट चुनें',
        airlineCalling: 'एयरलाइन कॉलिंग',
        connect: 'कनेक्ट करें',
    },
    sesotho: {
        selectLanguage: 'Ka kopo, kgetha puo ya hao',
        backMainMenu: 'Khutlela ho Main Menu',
        helpSubtitle: 'Re ka o thusa jwang kajeno?',
        healthWellness: 'Tlhahlobo ea Bophelo bo Botle le Bophelo bo Botle',
        denSurvey: "Tlhahlobo ea Ts'ebeletso ea Bareki ea DEN",
        emergencyAccess: 'Litšebeletso tsa phihlello ea Tšohanyetso',
        multilingualCalling: 'Pitso ka dipuo tse ngata',
        freeCalling: 'Ho Letsa Mahala',
        denLiveVideo: 'DEN Moemeli oa fumaneha ka ',
        multilingualChatRoom: 'Puisano ka Lipuo Tse Ngata ',
        denMarketplace: 'Den Boema-fofane ',
        qrCodeCreator: 'Lefatšeng ka bophara ',
        ttyTtd: 'TTY/TDD ke ea litšebeletso tsa phepelo ea lifitio bakeng sa batho ba sa utloeng litsebeng le ba sa utloeng litsebeng',
        airlineAgents: 'Baemeli ba lifofane',
        personalCall: 'Pitso ea hau',
        phoneNumber: 'Nomoro ea Mohala',
        selectAirline: 'Khetha moemeli oa hau oa sefofane',
        airlineCalling: 'Ho letsa ka sefofane',
        connect: 'Hokela',
    },
    lt: {
        selectLanguage: 'Pasirinkite savo kalbą',
        backMainMenu: 'Atgal į pagrindinį meniu',
        helpSubtitle: 'Kaip mes galime jums padėti šiandien?',
        healthWellness: 'Sveikatos ir gerovės patikrinimas',
        denSurvey: 'DEN klientų aptarnavimo apklausa',
        emergencyAccess: 'Avarinė prieiga ',
        multilingualCalling: 'Kelių kalbų skambinimas',
        freeCalling: 'Nemokamas skambutis',
        denLiveVideo: 'DEN Tiesioginis vaizdo įrašas ',
        multilingualChatRoom: 'Kelių kalbų pokalbių kambarys',
        denMarketplace: 'DEN oro uostas ',
        qrCodeCreator: 'globalus ',
        ttyTtd: 'TTY/TDD skirtas vaizdo perdavimo paslaugoms kurtiesiems ir klausos negalią turintiems žmonėms',
        airlineAgents: 'Oro linijų agentai',
        personalCall: 'Asmeninis skambutis',
        phoneNumber: 'Telefono numeris',
        selectAirline: 'Pasirinkite savo oro linijų agentą',
        airlineCalling: 'Oro linijų skambučiai',
        connect: 'Prisijungti',
    },
    russian: {
        selectLanguage: 'Пожалуйста, выберите ваш язык',
        backMainMenu: 'Вернуться в главное меню',
        helpSubtitle: 'Как мы можем помочь тебе сегодня?',
        healthWellness: 'Проверка здоровья и благополучия',
        denSurvey: 'Опрос службы поддержки клиентов DEN',
        emergencyAccess: 'Службы экстренного доступа',
        multilingualCalling: 'Многоязычные звонки',
        freeCalling: 'Бесплатные звонки',
        denLiveVideo: 'DEN Живое видео ',
        multilingualChatRoom: 'Многоязычный чат',
        denMarketplace: 'Торговая площадка аэропорта DEN',
        qrCodeCreator: 'Глобальная торговая площадка',
        ttyTtd: 'TTY/TDD предназначен для услуг видеоретрансляции для глухих и слабослышащих.',
        airlineAgents: 'Агенты авиакомпаний',
        personalCall: 'Персональный звонок',
        phoneNumber: 'Номер телефона',
        selectAirline: 'Выберите агента авиакомпании',
        airlineCalling: 'Вызов авиакомпании',
        connect: 'Соединять',
    },
    kurdish: {
        selectLanguage: 'Ji kerema xwe zimanê xwe hilbijêre',
        backMainMenu: 'Vegere pêşeka sereke',
        helpSubtitle: 'Em îro çawa dikarin alîkariya te bikin?',
        healthWellness: 'Kontrolkirina Tenduristî û Xweşiyê',
        denSurvey: 'Lêkolîna Xizmeta Mişterî ya DEN',
        emergencyAccess: 'Xizmetên Gihîştina Lezgîn',
        multilingualCalling: 'Banga Pirzimanî',
        freeCalling: 'Banga Bêla',
        denLiveVideo: 'DEN Kesê Vîdyoyê Zindî',
        multilingualChatRoom: 'Odeya Chat Pirzimanî',
        denMarketplace: 'Bazara Balafirgeha DEN',
        qrCodeCreator: 'Bazara Cîhanî',
        ttyTtd: 'TTY/TDD ji bo karûbarên veguheztina vîdyoyê ji bo kerr û astengdarên bihîstinê ye',
        airlineAgents: 'Ajanên balafirgehê',
        personalCall: 'Banga Kesane',
        phoneNumber: 'Hejmara têlefonê',
        selectAirline: 'Agentê balafirgeha xwe hilbijêre',
        airlineCalling: 'Balgiriya Hewayê',
        Connecter: 'Girêdan',
    },
    nl: {
        selectLanguage: 'Selecteer uw taal',
        backMainMenu: 'Terug naar het hoofdmenu',
        helpSubtitle: 'Hoe kunnen we u vandaag helpen?',
        healthWellness: 'Gezondheids- en welzijnscontrole',
        denSurvey: 'DEN Klantenserviceonderzoek',
        emergencyAccess: 'Toegangsdiensten voor noodgevallen',
        multilingualCalling: 'Meertalig bellen',
        freeCalling: 'Gratis bellen',
        denLiveVideo: 'DEN Live videoagent',
        multilingualChatRoom: 'Meertalige chatroom',
        denMarketplace: 'DEN Airport Marktplaats',
        qrCodeCreator: 'Wereldwijde marktplaats',
        ttyTtd: 'TTY/TDD is bedoeld voor videorelaydiensten voor doven en slechthorenden',
        airlineAgents: 'Luchtvaartagenten',
        personalCall: 'Persoonlijke oproep',
        phoneNumber: 'Telefoonnummer',
        selectAirline: 'Selecteer uw luchtvaartmaatschappij',
        airlineCalling: 'Luchtvaartmaatschappij bellen',
        connect: 'Aansluiten',
    },
    catalan: {
        selectLanguage: 'Seleccioneu el vostre idioma',
        backMainMenu: 'Tornar al menú principal',
        helpSubtitle: 'Com et podem ajudar avui?',
        healthWellness: 'Control de salut i benestar',
        denSurvey: "Enquesta d'atenció al client de DEN",
        emergencyAccess: "Serveis d'accés d'emergència",
        multilingualCalling: 'Trucada multilingüe',
        freeCalling: 'Trucades gratuïtes',
        denLiveVideo: 'Agent de vídeo en viu de DEN',
        multilingualChatRoom: 'Sala de xat multilingüe',
        denMarketplace: "Mercat de l'aeroport de DEN",
        qrCodeCreator: 'Mercat global',
        ttyTtd: 'TTY/TDD és per a serveis de retransmissió de vídeo per a sords i amb discapacitat auditiva',
        airlineAgents: 'Agents de línies aèries',
        personalCall: 'Trucada personal',
        phoneNumber: 'Número de telèfon',
        selectAirline: 'Seleccioneu el vostre agent aeri',
        airlineCalling: 'Trucades aèries',
        connect: "Connecta't",
    },
    or: {
        selectLanguage: 'ଦୟାକରି ଆପଣଙ୍କର ଭାଷା ଚୟନ କରନ୍ତୁ',
        backMainMenu: 'ମେନ୍ ମେନୁକୁ ଫେରନ୍ତୁ',
        helpSubtitle: 'ଆଜି ଆମେ ଆପଣଙ୍କୁ କିପରି ସାହାଯ୍ୟ କରିପାରିବା?',
        healthWellness: 'ସ୍ୱାସ୍ଥ୍ୟ ଏବଂ ୱେଲନେସ୍ ଯା Check ୍ଚ',
        denSurvey: 'DEN ଗ୍ରାହକ ସେବା ସର୍ବେକ୍ଷଣ',
        emergencyAccess: 'ଜରୁରୀକାଳୀନ ପ୍ରବେଶ ସେବା',
        multilingualCalling: 'ବହୁଭାଷୀ କଲିଂ',
        freeCalling: 'ମାଗଣା କଲିଂ',
        denLiveVideo: 'DEN ଲାଇଭ ଭିଡିଓ ଏଜେଣ୍ଟ',
        multilingualChatRoom: 'ବହୁଭାଷୀ ଚାଟ୍ ରୁମ୍',
        denMarketplace: 'DEN ଅନ୍ଲାଇନ୍ ମାର୍କେଟପ୍ଲେସ୍',
        qrCodeCreator: 'DEN ଅନ୍ଲାଇନ୍ ମାର୍କେଟପ୍ଲେସ୍',
        ttyTtd: 'TTY / TDD ବଧିର ଏବଂ ଶ୍ରବଣ ଦୁର୍ବଳଙ୍କ ପାଇଁ ଭିଡିଓ ରିଲେ ସେବା ପାଇଁ',
        airlineAgents: 'ଏୟାରଲାଇନ୍ସ ଏଜେଣ୍ଟ',
        personalCall: 'ବ୍ୟକ୍ତିଗତ କଲ୍',
        phoneNumber: 'ଫୋନ୍ ନମ୍ବର',
        selectAirline: 'ଆପଣଙ୍କର ବିମାନ ଏଜେଣ୍ଟ ଚୟନ କରନ୍ତୁ',
        airlineCalling: 'ଏୟାରଲାଇନ୍ କଲିଂ',
        connect: 'ସଂଯୋଗ',
    },
    filipino: {
        selectLanguage: 'Pakipili ang iyong wika',
        backMainMenu: 'Bumalik sa Pangunahing Menu',
        helpSubtitle: 'Paano ka namin matutulungan ngayon?',
        healthWellness: 'Health and Welness Check',
        denSurvey: 'Survey sa Customer Service ng DEN',
        emergencyAccess: 'Mga Serbisyong Pang-emergency na Access',
        multilingualCalling: 'Multilingual na pagtawag',
        freeCalling: 'Libreng Pagtawag',
        denLiveVideo: 'DEN Agent sa video',
        multilingualChatRoom: 'Maramihang mga wika chat',
        denMarketplace: 'DEN pamilihan ng paliparan',
        qrCodeCreator: 'pandaigdigang pamilihan',
        ttyTtd: 'Ang TTY/TDD ay para sa mga serbisyo ng video relay para sa mga bingi at may kapansanan sa pandinig',
        airlineAgents: 'Mga ahente ng airline',
        personalCall: 'Personal na Tawag',
        phoneNumber: 'Numero ng Telepono',
        selectAirline: 'Piliin ang iyong ahente ng airline',
        airlineCalling: 'Pagtawag sa Airline',
        connect: 'Connect',
    },
    'amharic-ethiopia': {
        selectLanguage: 'እባክዎ ቋንቋዎን ይምረጡ',
        backMainMenu: 'ወደ ዋና ምናሌ ተመለስ',
        helpSubtitle: 'ዛሬ እንዴት ልንረዳዎ እንችላለን?',
        healthWellness: 'የጤና እና የጤንነት ማረጋገጫ',
        denSurvey: 'DEN የደንበኞች አገልግሎት ዳሰሳ',
        emergencyAccess: 'የአደጋ ጊዜ መዳረሻ አገልግሎቶች',
        multilingualCalling: 'የብዙ ቋንቋ ጥሪ',
        freeCalling: 'ነጻ ጥሪ',
        denLiveVideo: 'DEN የቀጥታ ቪዲዮ ወኪል',
        multilingualChatRoom: 'የብዙ ቋንቋ ውይይት ክፍል',
        denMarketplace: 'DEN የአየር ማረፊያ የገበያ ቦታ',
        qrCodeCreator: 'ዓለም አቀፍ የገበያ ቦታ',
        ttyTtd: 'TTY/TDD መስማት ለተሳናቸው እና መስማት ለተሳናቸው የቪዲዮ ማስተላለፊያ አገልግሎት ነው',
        airlineAgents: 'የአየር መንገድ ወኪሎች',
        personalCall: 'የግል ጥሪ',
        phoneNumber: 'ስልክ ቁጥር',
        selectAirline: 'የአየር መንገድ ወኪልዎን ይምረጡ',
        airlineCalling: 'የአየር መንገድ ጥሪ',
        connect: 'አገናኝ',
    },
    somali: {
        selectLanguage: 'Fadlan door luqadaada',
        backMainMenu: 'Ku laabo Menu Main',
        helpSubtitle: 'Sideen ku caawin karnaa maanta?',
        healthWellness: 'Caafimaadka iyo fayoobaanta',
        denSurvey: 'DEN sahanka adeegga macaamiisha',
        emergencyAccess: 'Adeegyada Helitaanka Degdega ah',
        multilingualCalling: 'Wacitaanka luuqadaha badan',
        freeCalling: 'Wicitaan bilaash ah',
        denLiveVideo: 'Wakiilka Fiidyaha tooska ah ee DEN',
        multilingualChatRoom: 'Qolka Wadahadalka Luuqadaha Badan',
        denMarketplace: 'DEN Suuqyada Garoonka Diyaaradaha',
        qrCodeCreator: 'suuqa global',
        ttyTtd: 'TTY/TDD waxaa loogu talagalay adeegyada gudbinta fiidyaha ee dhagoolayaasha iyo maqalka naafada',
        airlineAgents: 'Wakiilada diyaaradaha',
        personalCall: 'Wacitaanka Shakhsi ahaaneed',
        phoneNumber: 'Telefoon Number',
        selectAirline: 'Dooro wakiilkaaga diyaarad',
        airlineCalling: 'Wicitaan Diyaaradeed',
        connect: 'isku xidhid',
    },
    gujarati: {
        selectLanguage: 'કૃપા કરીને તમારી ભાષા પસંદ કરો',
        backMainMenu: 'મુખ્ય મેનુ પર પાછા',
        helpSubtitle: 'આજે અમે તમને કેવી રીતે મદદ કરી શકીએ?',
        healthWellness: 'સ્વાસ્થ્ય અને સુખાકારી તપાસ',
        denSurvey: 'DEN ગ્રાહક સેવા સર્વેક્ષણ',
        emergencyAccess: 'ઇમરજન્સી એક્સેસ સેવાઓ',
        multilingualCalling: 'બહુભાષી કૉલિંગ',
        freeCalling: 'મફત કૉલિંગ',
        denLiveVideo: 'DEN લાઇવ વિડિયો એજન્ટ',
        multilingualChatRoom: 'બહુભાષી ચેટ રૂમ',
        denMarketplace: 'DEN એરપોર્ટ માર્કેટપ્લેસ',
        qrCodeCreator: 'ગ્લોબલ માર્કેટપ્લેસ',
        ttyTtd: 'TTY/TDD એ બહેરા અને સાંભળવાની ક્ષતિઓ માટે વિડિયો રિલે સેવાઓ માટે છે',
        airlineAgents: 'એરલાઇન એજન્ટ',
        personalCall: 'વ્યક્તિગત કૉલ',
        phoneNumber: 'ફોન નંબર',
        selectAirline: 'તમારા એરલાઇન એજન્ટને પસંદ કરો',
        airlineCalling: 'એરલાઇન કૉલિંગ',
        connect: 'જોડો',
    },
    pashto: {
        selectLanguage: 'مهرباني وکړئ خپله ژبه وټاکئ',
        backMainMenu: 'شاته اصلي مینو ته',
        helpSubtitle: 'نن موږ څنګه ستاسو سره مرسته کولی شو؟',
        healthWellness: 'روغتیا او هوساینه معاینه',
        denSurvey: 'د پیرودونکو خدماتو سروې',
        emergencyAccess: 'د بیړني لاسرسي خدمتونه',
        multilingualCalling: 'څو ژبو غږ کول',
        freeCalling: 'وړيا زنګ وهل',
        denLiveVideo: 'د ژوندی ویډیو ایجنټ',
        multilingualChatRoom: 'د څو ژبو د خبرو خونه',
        denMarketplace: 'د هوايي ډګر بازار ځای',
        qrCodeCreator: 'نړیوال بازار ځای',
        ttyTtd: 'TTY/TDD د کاڼو او اورېدلو معیوبینو لپاره د ویډیو ریلي خدماتو لپاره دی',
        airlineAgents: 'د هوايي چلند استازي',
        personalCall: 'شخصي زنګ وهل',
        phoneNumber: 'د تلیفون شمیره',
        selectAirline: 'د خپل هوايي شرکت استازی وټاکئ',
        airlineCalling: 'د هوايي کرښې زنګ وهل',
        connect: 'وصل',
    },
    kazakh: {
        selectLanguage: 'Тілді таңдаңыз',
        backMainMenu: 'Негізгі мәзірге оралу',
        helpSubtitle: 'Бүгін сізге қалай көмектесе аламыз?',
        healthWellness: 'Денсаулық пен денсаулықты тексеру',
        denSurvey: 'DEN тұтынушыларға қызмет көрсету сауалнамасы',
        emergencyAccess: 'Төтенше қол жеткізу қызметтері',
        multilingualCalling: 'Көп тілді шақыру',
        freeCalling: 'Тегін қоңырау шалу',
        denLiveVideo: 'DEN Тікелей бейне агенті',
        multilingualChatRoom: 'Көп тілді сөйлесу бөлмесі',
        denMarketplace: 'DEN әуежай базары',
        qrCodeCreator: 'Жаһандық нарық',
        ttyTtd: 'TTY/TDD саңыраулар мен есту қабілеті нашар адамдарға арналған бейнерелейлік қызметтерге арналған',
        airlineAgents: 'Авиакомпания агенттері',
        personalCall: 'Жеке қоңырау',
        phoneNumber: 'Телефон нөмірі',
        selectAirline: 'Авиакомпания агентін таңдаңыз',
        airlineCalling: 'Авиакомпанияға қоңырау шалу',
        connect: 'қосылу',
    },
    burmese: {
        selectLanguage: 'သင့်ဘာသာစကားကို ရွေးချယ်ပါ',
        backMainMenu: 'ပင်မမီနူးသို့ ပြန်သွားရန်',
        helpSubtitle: 'ဒီနေ့ မင်းကို ဘယ်လိုကူညီနိုင်မလဲ။',
        healthWellness: 'ကျန်းမာရေးနှင့် ကျန်းမာရေး စစ်ဆေးခြင်း',
        denSurvey: 'DEN ဖောက်သည်ဝန်ဆောင်မှုစစ်တမ်း',
        emergencyAccess: 'အရေးပေါ်ဝင်ရောက်မှုဝန်ဆောင်မှုများ',
        multilingualCalling: 'ဘာသာစကားမျိုးစုံခေါ်ဆိုခြင်း',
        freeCalling: 'အခမဲ့ခေါ်ဆိုခြင်း',
        denLiveVideo: 'DEN တိုက်ရိုက်ဗီဒီယို ကိုယ်စားလှယ်',
        multilingualChatRoom: 'ဘာသာစုံ စကားပြောခန်း',
        denMarketplace: 'DEN လေဆိပ်ဈေး',
        qrCodeCreator: 'ကမ္ဘာ့စျေးကွက်',
        ttyTtd: 'TTY/TDD သည် နားမကြားသူများနှင့် အကြားအာရုံချို့ယွင်းသူများအတွက် ဗီဒီယို ထပ်ဆင့်လွှင့်ခြင်းဝန်ဆောင်မှုများအတွက်ဖြစ်သည်',
        airlineAgents: 'လေကြောင်းလိုင်း အေးဂျင့်များ',
        personalCall: 'ကိုယ်ရေးကိုယ်တာခေါ်ဆိုမှု',
        phoneNumber: 'ဖုန်းနံပါတ်',
        selectAirline: 'သင့်လေကြောင်းလိုင်း ကိုယ်စားလှယ်ကို ရွေးချယ်ပါ',
        airlineCalling: 'လေကြောင်းလိုင်းခေါ်ဆိုမှု',
        connect: 'ချိတ်ဆက်',
    },
    turkish: {
        selectLanguage: 'Lütfen dilinizi seçin',
        backMainMenu: 'Ana Menüye Dön',
        helpSubtitle: 'Bugün size nasıl yardımcı olabiliriz?',
        healthWellness: 'Sağlık ve Refah Kontrolü',
        denSurvey: 'DEN Müşteri Hizmetleri Anketi',
        emergencyAccess: 'Acil Erişim Hizmetleri',
        multilingualCalling: 'Çok dilli arama',
        freeCalling: 'Ücretsiz Arama',
        denLiveVideo: 'DEN Canlı Video Aracısı',
        multilingualChatRoom: 'Çok Dilli Sohbet Odası',
        denMarketplace: 'DEN Havaalanı Pazaryeri',
        qrCodeCreator: 'Küresel Pazaryeri',
        ttyTtd: 'TTY/TDD, sağır ve işitme engellilere yönelik video aktarım hizmetleri içindir',
        airlineAgents: 'Havayolu acenteleri',
        personalCall: 'Kişisel Arama',
        phoneNumber: 'Telefon Numarası',
        selectAirline: 'Havayolu acentenizi seçin',
        airlineCalling: 'Havayolu Araması',
        Connectà: 'Bağlan',
    },
    'scots-gaelic': {
        selectLanguage: 'Tagh do chànan',
        backMainMenu: 'Air ais dhan phrìomh chlàr',
        helpSubtitle: 'Ciamar as urrainn dhuinn do chuideachadh an-diugh?',
        healthWellness: 'Sgrùdadh Slàinte is Sunnd',
        denSurvey: 'Suirbhidh Seirbheis Luchd-cleachdaidh DEN',
        emergencyAccess: 'Seirbheisean inntrigidh èiginneach',
        multilingualCalling: 'Gairm ioma-chànanach',
        freeCalling: 'Gairm an-asgaidh',
        denLiveVideo: 'Buidheann Bhideo Beò DEN',
        multilingualChatRoom: 'Seòmar còmhraidh ioma-chànanach',
        denMarketplace: 'Margaidh Port-adhair DEN',
        qrCodeCreator: 'Margadh na Cruinne',
        ttyTtd: 'Tha TTY/TDD airson seirbheisean sealaidheachd bhidio dha daoine bodhar is le duilgheadasan claisneachd',
        airlineAgents: 'Riochdairean itealain',
        personalCall: 'Call pearsanta',
        phoneNumber: 'Àireamh Fòn',
        selectAirline: 'Tagh àidseant na companaidh adhair agad',
        airlineCalling: 'Cuairt-adhair',
        connect: 'Ceangail',
    },
    bengali: {
        selectLanguage: 'দয়া করে আপনার ভাষা নির্বাচন করুন',
        backMainMenu: 'প্রধান মেনুতে ফিরে যান',
        helpSubtitle: 'আজ আমরা কীভাবে আপনাকে সাহায্য করতে পারি?',
        healthWellness: 'স্বাস্থ্য এবং সুস্থতা পরীক্ষা',
        denSurvey: 'ডেন গ্রাহক পরিষেবা জরিপ',
        emergencyAccess: 'জরুরী অ্যাক্সেস পরিষেবা',
        multilingualCalling: 'বহুভাষিক কলিং',
        freeCalling: 'ফ্রি কলিং',
        denLiveVideo: 'ডেন লাইভ ভিডিও এজেন্ট',
        multilingualChatRoom: 'বহুভাষিক চ্যাট রুম',
        denMarketplace: 'DEN বিমানবন্দর মার্কেটপ্লেস',
        qrCodeCreator: 'গ্লোবাল মার্কেটপ্লেস',
        ttyTtd: 'টিটিওয়াই/টিডিডি বধির এবং শ্রবণ প্রতিবন্ধীদের জন্য ভিডিও রিলে পরিষেবার জন্য',
        airlineAgents: 'এয়ারলাইন এজেন্ট',
        personalCall: 'ব্যক্তিগত কল',
        phoneNumber: 'ফোন নম্বর',
        selectAirline: 'আপনার এয়ারলাইন এজেন্ট নির্বাচন করুন',
        airlineCalling: 'এয়ারলাইন কলিং',
        connect: 'সংযোগ',
    },
    ml: {
        selectLanguage: 'ദയവായി നിങ്ങളുടെ ഭാഷ തിരഞ്ഞെടുക്കുക',
        backMainMenu: 'മെയിൻ മെനുവിലേക്ക് മടങ്ങുക',
        helpSubtitle: 'ഞങ്ങൾക്ക് ഇന്ന് നിങ്ങളെ എങ്ങനെ സഹായിക്കാനാകും?',
        healthWellness: 'ആരോഗ്യവും ആരോഗ്യ പരിശോധനയും',
        denSurvey: 'DEN കസ്റ്റമർ സർവീസ് സർവേ',
        emergencyAccess: 'അടിയന്തര പ്രവേശന സേവനങ്ങൾ',
        multilingualCalling: 'ബഹുഭാഷാ കോളിംഗ്',
        freeCalling: 'സൌജന്യ കോളിംഗ്',
        denLiveVideo: 'DEN ലൈവ് വീഡിയോ ഏജൻ്റ്',
        multilingualChatRoom: 'ബഹുഭാഷാ ചാറ്റ് റൂം',
        denMarketplace: 'DEN എയർപോർട്ട് മാർക്കറ്റ്പ്ലേസ്',
        qrCodeCreator: 'ആഗോള വിപണി',
        ttyTtd: 'TTY/TDD ബധിരർക്കും ശ്രവണ വൈകല്യമുള്ളവർക്കും വേണ്ടിയുള്ള വീഡിയോ റിലേ സേവനങ്ങൾക്കുള്ളതാണ്',
        airlineAgents: 'എയർലൈൻ ഏജൻ്റുകൾ',
        personalCall: 'വ്യക്തിഗത കോൾ',
        phoneNumber: 'ഫോൺ നമ്പർ',
        selectAirline: 'നിങ്ങളുടെ എയർലൈൻ ഏജൻ്റ് തിരഞ്ഞെടുക്കുക',
        airlineCalling: 'എയർലൈൻ കോളിംഗ്',
        connect: 'കണക്‌റ്റ്',
    },
    nepali: {
        selectLanguage: 'कृपया आफ्नो भाषा चयन गर्नुहोस्',
        backMainMenu: 'मुख्य मेनुमा फर्कनुहोस्',
        helpSubtitle: 'हामी आज तपाईलाई कसरी मद्दत गर्न सक्छौं?',
        healthWellness: 'स्वास्थ्य र कल्याण जाँच',
        denSurvey: 'DEN ग्राहक सेवा सर्वेक्षण',
        emergencyAccess: 'आपत्कालीन पहुँच सेवा',
        multilingualCalling: 'बहुभाषी कल',
        freeCalling: 'फ्री कलिङ',
        denLiveVideo: 'DEN लाइभ भिडियो एजेन्ट',
        multilingualChatRoom: 'बहुभाषी च्याट कोठा',
        denMarketplace: 'डेन एयरपोर्ट मार्केटप्लेस',
        qrCodeCreator: 'ग्लोबल मार्केटप्लेस',
        ttyTtd: 'TTY/TDD बहिरा र सुन्न नसक्नेहरूका लागि भिडियो रिले सेवाहरूको लागि हो',
        airlineAgents: 'एयरलाइन एजेन्टहरू',
        personalCall: 'व्यक्तिगत कल',
        phoneNumber: 'फोन नम्बर',
        selectAirline: 'तपाईँको एयरलाइन एजेन्ट छान्नुहोस्',
        airlineCalling: 'एयरलाइन कलिङ',
        connect: 'जोड्नुहोस्',
    },
    croatian: {
        selectLanguage: 'Molimo odaberite svoj jezik',
        backMainMenu: 'Natrag na glavni izbornik',
        helpSubtitle: 'Kako vam možemo pomoći danas?',
        healthWellness: 'Provjera zdravlja i blagostanja',
        denSurvey: 'DEN anketa o korisničkoj službi',
        emergencyAccess: 'Usluge hitnog pristupa',
        multilingualCalling: 'Višejezično pozivanje',
        freeCalling: 'Besplatno pozivanje',
        denLiveVideo: 'DEN video agent uživo',
        multilingualChatRoom: 'Višejezična soba za razgovor',
        denMarketplace: 'DEN Airport Marketplace',
        qrCodeCreator: 'Globalno tržište',
        ttyTtd: 'TTY/TDD je za usluge video releja za gluhe i osobe oštećena sluha',
        airlineAgents: 'Agenti zrakoplovne kompanije',
        personalCall: 'Osobni poziv',
        phoneNumber: 'Telefonski broj',
        selectAirline: 'Odaberite svog agenta zrakoplovne kompanije',
        airlineCalling: 'Poziv iz zrakoplovne kompanije',
        connect: 'Poveži se',
    },
    korean: {
        selectLanguage: '언어를 선택하세요',
        backMainMenu: '메인 메뉴로 돌아가기',
        helpSubtitle: '오늘은 어떻게 도와드릴까요?',
        healthWellness: '건강 및 복지 점검',
        denSurvey: 'DEN 고객 서비스 설문조사',
        emergencyAccess: '긴급 접근 서비스',
        multilingualCalling: '다국어 통화',
        freeCalling: '무료 통화',
        denLiveVideo: 'DEN 라이브 비디오 에이전트',
        multilingualChatRoom: '다국어 채팅방',
        denMarketplace: 'DEN 공항 마켓플레이스',
        qrCodeCreator: '글로벌 마켓플레이스',
        ttyTtd: 'TTY/TDD는 청각 장애인을 위한 영상 중계 서비스입니다.',
        airlineAgents: '항공사 직원',
        personalCall: '개인 통화',
        phoneNumber: '전화 번호',
        selectAirline: '항공사 대리인을 선택하세요',
        airlineCalling: '항공사 전화',
        connect: '연결하다',
    },
    nyanja: {
        selectLanguage: 'Chonde sankhani chinenero chanu',
        backMainMenu: 'Bwerera ku Menyu Yaikulu',
        helpSubtitle: 'Kodi tingakuthandizeni bwanji lero?',
        healthWellness: 'thanzi ndi bwino cheke',
        denSurvey: 'Mafunso a makasitomala',
        emergencyAccess: 'Thandizo Lofulumira',
        multilingualCalling: 'Kuyitana zilankhulo zambiri',
        freeCalling: 'Kuyitana Kwaulere',
        denLiveVideo: 'DEN thandizo la kanema',
        multilingualChatRoom: 'Malo Ochezera a Zilankhulo Zambiri',
        denMarketplace: 'DEN Msika wa Ndege',
        qrCodeCreator: 'Msika wapadziko lonse',
        ttyTtd: 'TY / TDD ndi ya ntchito zoperekera mavidiyo kwa ogontha ndi ovutika kumva',
        airlineAgents: 'Thandizo la ndege',
        personalCall: 'Kuitana Kwaumwini',
        phoneNumber: 'Nambala ya Foni',
        selectAirline: 'Sankhani wothandizira ndege wanu',
        airlineCalling: 'kuitana kwa ndege',
        connect: 'Lumikizanani',
    },
    danish: {
        selectLanguage: 'Vælg venligst dit sprog',
        backMainMenu: 'Tilbage til hovedmenu',
        helpSubtitle: 'Hvordan kan vi hjælpe dig i dag?',
        healthWellness: 'Sundheds- og velværetjek',
        denSurvey: 'DEN Kundeserviceundersøgelse',
        emergencyAccess: 'Nødadgangstjenester',
        multilingualCalling: 'Flersproget opkald',
        freeCalling: 'Gratis opkald',
        denLiveVideo: 'DEN person på video',
        multilingualChatRoom: 'Flersproget chatrum',
        denMarketplace: 'DEN Lufthavn Marketplace',
        qrCodeCreator: 'Gverden markedsplads',
        ttyTtd: 'TTY/TDD er til videorelætjenester for døve og hørehæmmede',
        airlineAgents: 'Flyselskabsagenter',
        personalCall: 'Personligt opkald',
        phoneNumber: 'Telefonnummer',
        selectAirline: 'Vælg din flyselskabsagent',
        airlineCalling: 'Flyselskab ringer',
        connect: 'Forbind',
    },
    id: {
        selectLanguage: 'Silakan pilih bahasa Anda',
        backMainMenu: 'Kembali ke Menu Utama',
        helpSubtitle: 'Apa yang bisa kami bantu hari ini?',
        healthWellness: 'Pemeriksaan Kesehatan dan Kesejahteraan',
        denSurvey: 'Survei Layanan Pelanggan DEN',
        emergencyAccess: 'Layanan Akses Darurat',
        multilingualCalling: 'Panggilan multibahasa',
        freeCalling: 'Panggilan Gratis',
        denLiveVideo: 'Agen Video Langsung DEN',
        multilingualChatRoom: 'Ruang Obrolan Multibahasa',
        denMarketplace: 'Pasar Bandara DEN',
        qrCodeCreator: 'Pasar Global',
        ttyTtd: 'TTY/TDD untuk layanan relai video bagi tuna rungu dan gangguan pendengaran',
        airlineAgents: 'Agen maskapai penerbangan',
        personalCall: 'Panggilan Pribadi',
        phoneNumber: 'Nomor Telepon',
        selectAirline: 'Pilih agen maskapai penerbangan Anda',
        airlineCalling: 'Panggilan Maskapai',
        connect: 'Menghubungkan',
    },
    czech: {
        selectLanguage: 'Vyberte prosím svůj jazyk',
        backMainMenu: 'Zpět do hlavní nabídky',
        helpSubtitle: 'Jak vám dnes můžeme pomoci?',
        healthWellness: 'Kontrola zdraví a wellness',
        denSurvey: 'Průzkum zákaznických služeb DEN',
        emergencyAccess: 'Služby nouzového přístupu',
        multilingualCalling: 'Vícejazyčné volání',
        freeCalling: 'Volání zdarma',
        denLiveVideo: 'DEN Osoba na videu',
        multilingualChatRoom: 'Vícejazyčná chatovací místnost',
        denMarketplace: 'DEN Letištní tržiště',
        qrCodeCreator: 'Globální tržiště',
        ttyTtd: 'TTY/TDD je pro služby přenosu videa pro neslyšící a sluchově postižené',
        airlineAgents: 'Agenti leteckých společností',
        personalCall: 'Osobní hovor',
        phoneNumber: 'Telefonní číslo',
        selectAirline: 'Vyberte svého leteckého agenta',
        airlineCalling: 'Volání letecké společnosti',
        connect: 'Připojit',
    },
    uyghur: {
        selectLanguage: 'تىلىڭىزنى تاللاڭ',
        backMainMenu: 'باش تىزىملىككە قايتىش',
        helpSubtitle: 'بۈگۈن بىز سىزگە قانداق ياردەم قىلالايمىز؟',
        healthWellness: 'ساغلاملىق ۋە ساغلاملىق تەكشۈرۈشى',
        denSurvey: 'DEN خېرىدارلار مۇلازىمىتىنى تەكشۈرۈش',
        emergencyAccess: 'جىددى ئەھۋالغا تاقابىل تۇرۇش مۇلازىمىتى',
        multilingualCalling: 'كۆپ تىللىق چاقىرىش',
        freeCalling: 'ھەقسىز چاقىرىش',
        denLiveVideo: 'DEN نەق مەيدان سىن ۋاكالەتچىسى',
        multilingualChatRoom: 'كۆپ تىللىق پاراڭلىشىش ئۆيى',
        denMarketplace: 'DEN ئايرودروم بازىرى',
        qrCodeCreator: 'يەرشارى بازىرى',
        ttyTtd: 'TTY / TDD گاس ۋە ئاڭلاش ئىقتىدارى توسالغۇغا ئۇچرىغانلارغا سىن يەتكۈزۈش مۇلازىمىتى ئۈچۈن',
        airlineAgents: 'ئاۋىئاتسىيە ۋاكالەتچىسى',
        personalCall: 'شەخسىي تېلېفون',
        phoneNumber: 'تېلېفون نومۇرى',
        selectAirline: 'ئاۋىئاتسىيە ۋاكالەتچىڭىزنى تاللاڭ',
        airlineCalling: 'ئاۋىئاتسىيە شىركىتى چاقىرىش',
        connect: 'ئۇلىنىش',
    },
    zh: {
        selectLanguage: '请选择您的语言',
        backMainMenu: '返回主菜单',
        helpSubtitle: '今天我们能为您提供什么帮助？',
        healthWellness: '健康与保健检查',
        denSurvey: 'DEN 客户服务调查',
        emergencyAccess: '紧急通道服务',
        multilingualCalling: '多语言通话',
        freeCalling: '免费通话',
        denLiveVideo: 'DEN 现场视频代理',
        multilingualChatRoom: '多语言聊天室',
        denMarketplace: 'DEN 机场市场',
        qrCodeCreator: '全球市场',
        ttyTtd: 'TTY/TDD 适用于聋哑人和听力障碍人士的视频转播服务',
        airlineAgents: '航空公司代理',
        personalCall: '个人电话',
        phoneNumber: '电话号码',
        selectAirline: '选择您的航空公司代理',
        airlineCalling: '航空公司呼叫',
        connect: '连接',
    },
    hawaiian: {
        selectLanguage: 'E ʻoluʻolu e koho i kāu ʻōlelo',
        backMainMenu: 'Hoʻi i ka papa kuhikuhi nui',
        helpSubtitle: 'Pehea mākou e kōkua ai iā ʻoe i kēia lā?',
        healthWellness: 'Hōʻike Ola a maikaʻi',
        denSurvey: 'Nānā lawelawe mea kūʻai mai DEN',
        emergencyAccess: 'Nā lawelawe hoʻokele pilikia',
        multilingualCalling: 'Kahea ʻōlelo he nui',
        freeCalling: 'Kahea manuahi',
        denLiveVideo: 'DEN Wikiō ola ',
        multilingualChatRoom: 'Luna kamaʻilio lehulehu',
        denMarketplace: 'Make kahua mokulele DEN',
        qrCodeCreator: 'Make kūʻai honua',
        ttyTtd: 'TTY/TDD no nā lawelawe hoʻolele wikiō no ka poʻe kuli a me ka hoʻolohe ʻino',
        airlineAgents: 'Nā luna mokulele',
        personalCall: 'Kahea pilikino',
        phoneNumber: 'Helu Kelepona',
        selectAirline: 'E koho i kāu ʻelele mokulele',
        airlineCalling: 'Kahea ʻana i ka mokulele',
        connect: 'Hoʻohui',
    },
    telugu: {
        selectLanguage: 'దయచేసి మీ భాషను ఎంచుకోండి',
        backMainMenu: 'తిరిగి ప్రధాన మెనూకి',
        helpSubtitle: 'ఈరోజు మేము మీకు ఎలా సహాయం చేయగలము?',
        healthWellness: 'హెల్త్ అండ్ వెల్నెస్ చెక్',
        denSurvey: 'DEN కస్టమర్ సర్వీస్ సర్వే',
        emergencyAccess: 'అత్యవసర యాక్సెస్ సేవలు',
        multilingualCalling: 'బహుభాషా కాలింగ్',
        freeCalling: 'ఉచిత కాలింగ్',
        denLiveVideo: 'DEN లైవ్ వీడియో ఏజెంట్',
        multilingualChatRoom: 'బహుభాషా చాట్ రూమ్',
        denMarketplace: 'DEN ఎయిర్‌పోర్ట్ మార్కెట్‌ప్లేస్',
        qrCodeCreator: 'గ్లోబల్ మార్కెట్‌ప్లేస్',
        ttyTtd: 'TTY/TDD అనేది చెవిటి మరియు వినికిడి లోపం ఉన్నవారి కోసం వీడియో రిలే సేవల కోసం',
        airlineAgents: 'ఎయిర్‌లైన్ ఏజెంట్లు',
        personalCall: 'వ్యక్తిగత కాల్',
        phoneNumber: 'ఫోన్ నంబర్',
        selectAirline: 'మీ ఎయిర్‌లైన్ ఏజెంట్‌ని ఎంచుకోండి',
        airlineCalling: 'ఎయిర్‌లైన్ కాలింగ్',
        connect: 'కనెక్ట్',
    },
    hebrew: {
        selectLanguage: 'אנא בחר את השפה שלך',
        backMainMenu: 'חזרה לתפריט הראשי',
        helpSubtitle: 'איך נוכל לעזור לך היום?',
        healthWellness: 'בדיקת בריאות ואיכות חיים',
        denSurvey: 'סקר שירות לקוחות DEN',
        emergencyAccess: 'שירותי גישה לשעת חירום',
        multilingualCalling: 'שיחה רב לשונית',
        freeCalling: 'שיחות חינם',
        denLiveVideo: 'סוכן וידאו חי של DEN',
        multilingualChatRoom: "חדר צ'אט רב לשוני",
        denMarketplace: 'DEN שוק שדה התעופה',
        qrCodeCreator: 'שוק גלובלי',
        ttyTtd: 'TTY/TDD מיועד לשירותי ממסר וידאו לחירשים ולקויי שמיעה',
        airlineAgents: 'סוכני חברות תעופה',
        personalCall: 'שיחה אישית',
        phoneNumber: 'מספר טלפון',
        selectAirline: 'בחר את סוכן חברת התעופה שלך',
        airlineCalling: 'חברת תעופה מתקשרת',
        connect: 'לְחַבֵּר',
    },
    xhosa: {
        selectLanguage: 'Nceda ukhethe ulwimi lwakho',
        backMainMenu: 'Buyela kwiMenu engundoqo',
        helpSubtitle: 'Singakunceda njani namhlanje?',
        healthWellness: 'Ukuhlolwa kweMpilo kunye nokuba sempilweni',
        denSurvey: 'UDEN uPhando lweNkonzo yabaThengi',
        emergencyAccess: 'Iinkonzo zoFikelelo oluNgxamisekileyo',
        multilingualCalling: 'Ukufowuna ngeelwimi ezininzi',
        freeCalling: 'Ukufowuna simahla',
        denLiveVideo: 'DEN iarhente yevidiyo ephilayo',
        multilingualChatRoom: 'Igumbi lokuncokola ngeelwimi ezininzi',
        denMarketplace: 'DEN indawo yentengiso yesikhululo seenqwelomoya',
        qrCodeCreator: 'IMarike yeHlabathi',
        ttyTtd: 'I-TTY/TDD yeyeenkonzo zokudlulisa ividiyo kubantu abangevayo nabangevayo',
        airlineAgents: 'iiarhente zeenqwelomoya',
        personalCall: 'Umnxeba wobuqu',
        phoneNumber: 'Inombolo yefowuni',
        selectAirline: 'Khetha iarhente yenqwelo moya',
        airlineCalling: 'Ukufowuna ngenqwelomoya',
        connect: 'Qhagamshela',
    },
    estonian: {
        selectLanguage: 'Palun valige oma keel',
        backMainMenu: 'Tagasi peamenüüsse',
        helpSubtitle: 'Kuidas saame teid täna aidata?',
        healthWellness: 'Tervise ja heaolu kontroll',
        denSurvey: 'DEN klienditeeninduse uuring',
        emergencyAccess: 'Hädaabi juurdepääsuteenused',
        multilingualCalling: 'Mitmekeelne helistamine',
        freeCalling: 'Tasuta helistamine',
        denLiveVideo: 'DEN inimene videol',
        multilingualChatRoom: 'Mitmekeelne jututuba',
        denMarketplace: 'DEN lennujaama turg',
        qrCodeCreator: 'Globaalne turg',
        ttyTtd: 'TTY/TDD on mõeldud kurtidele ja vaegkuuljatele mõeldud videoedastusteenuste jaoks',
        airlineAgents: 'lennufirma agendid',
        personalCall: 'Isiklik kõne',
        phoneNumber: 'Telefoninumber',
        selectAirline: 'Valige oma lennufirma agent',
        airlineCalling: 'Lennufirma helistamine',
        connect: 'Ühenda',
    },
    welsh: {
        selectLanguage: 'Dewiswch eich iaith ',
        backMainMenu: "Yn ôl i'r Brif Ddewislen",
        helpSubtitle: 'Sut gallwn ni eich helpu chi heddiw?',
        healthWellness: 'Gwiriad Iechyd a Lles',
        denSurvey: 'Arolwg Gwasanaeth Cwsmeriaid DEN',
        emergencyAccess: 'Gwasanaethau Mynediad Brys ',
        multilingualCalling: 'Galwad amlieithog',
        FreeGaling: 'Galw am Ddim',
        denLiveVideo: 'Asiant Fideo Byw DEN',
        multilingualChatRoom: 'Ystafell Sgwrsio Amlieithog',
        denMarketplace: 'Marchnad Maes Awyr DEN',
        qrCodeCreator: 'Marchnad Fyd-eang',
        ttyTtd: "Mae TTY/TDD ar gyfer gwasanaethau cyfnewid fideo i'r byddar a'r rhai â nam ar eu clyw",
        airlineAgents: 'Asiantau cwmni hedfan',
        personalCall: 'Galwad Bersonol',
        phoneNumber: 'Rhif Ffôn',
        selectAirline: 'Dewiswch eich asiant cwmni hedfan',
        airlineCalling: 'Galw cwmni hedfan',
        connect: 'Cysylltu',
    },
    'urdu-india': {
        selectLanguage: 'براہ کرم اپنی زبان منتخب کریں',
        backMainMenu: 'واپس مین مینو پر',
        helpSubtitle: 'آج ہم آپ کی مدد کیسے کر سکتے ہیں؟',
        healthWellness: 'صحت اور فلاح و بہبود کی جانچ',
        denSurvey: 'DEN کسٹمر سروس سروے',
        emergencyAccess: 'ہنگامی رسائی کی خدمات',
        multilingualCalling: 'کثیر لسانی کالنگ',
        freeCalling: 'مفت کالنگ',
        denLiveVideo: 'DEN لائیو ویڈیو ایجنٹ',
        multilingualChatRoom: 'کثیر لسانی چیٹ روم',
        denMarketplace: 'DEN ائیرپورٹ مارکیٹ پلیس',
        qrCodeCreator: 'عالمی بازار',
        ttyTtd: 'TTY/TDD بہرے اور سماعت سے محروم افراد کے لیے ویڈیو ریلے خدمات کے لیے ہے',
        airlineAgents: 'ایئر لائن ایجنٹس',
        personalCall: 'ذاتی کال',
        phoneNumber: 'فون نمبر',
        selectAirline: 'اپنے ایئر لائن ایجنٹ کو منتخب کریں',
        airlineCalling: 'ایئر لائن کالنگ',
        connect: 'متصل',
    },
    su: {
        selectLanguage: 'Punten pilih basa anjeun',
        backMainMenu: 'Balik deui ka Menu Utama',
        helpSubtitle: 'Kumaha urang tiasa ngabantosan anjeun ayeuna?',
        healthWellness: 'Cék Kaséhatan sareng Kasalametan',
        denSurvey: 'Survey Layanan Pelanggan DEN',
        emergencyAccess: 'Layanan Aksés Darurat',
        multilingualCalling: 'Télépon multibasa',
        freeCalling: 'Telepon Gratis',
        denLiveVideo: 'DEN jalma dina video',
        multilingualChatRoom: 'Ruang Obrolan Multibasa',
        denMarketplace: 'Pasar Bandara DEN',
        qrCodeCreator: 'Pasar Global',
        ttyTtd: 'TTY/TDD kanggo jasa relay vidéo pikeun tunarungu sareng tunarungu',
        airlineAgents: 'Agén maskapai',
        personalCall: 'Telepon Pribadi',
        phoneNumber: 'Nomer Telepon',
        selectAirline: 'Pilih agén maskapai anjeun',
        airlineCalling: 'Nelepon Maskapai Penerbangan',
        connect: 'Sambungkeun',
    },
    slovak: {
        selectLanguage: 'Vyberte svoj jazyk',
        backMainMenu: 'Späť do hlavnej ponuky',
        helpSubtitle: 'Ako vám dnes môžeme pomôcť?',
        healthWellness: 'Kontrola zdravia a blahobytu',
        denSurvey: 'Prieskum zákazníckych služieb DEN',
        emergencyAccess: 'Služby núdzového prístupu',
        multilingualCalling: 'Viacjazyčné volanie',
        freeCalling: 'Bezplatné volanie',
        denLiveVideo: 'DEN osoba na videu',
        multilingualChatRoom: 'Viacjazyčná chatová miestnosť',
        denMarketplace: 'DEN letiskové trhovisko',
        qrCodeCreator: 'Globálny trh',
        ttyTtd: 'TTY/TDD je pre služby prenosu videa pre nepočujúcich a sluchovo postihnutých',
        airlineAgents: 'Agenti leteckých spoločností',
        personalCall: 'Osobný hovor',
        phoneNumber: 'Telefónne číslo',
        selectAirline: 'Vyberte si agenta leteckej spoločnosti',
        airlineCalling: 'Volanie leteckej spoločnosti',
        connect: 'pripojiť',
    },
    udmurt: {
        selectLanguage: 'Пожалуйста, ачиз быръе кыл',
        backMainMenu: 'валтӥсь меню сьӧрам',
        helpSubtitle: 'Кызьы тон мыным туннэ юрттод быгатоз-а?',
        healthWellness: 'тазалык эскерон',
        denSurvey: 'юан-веран den клиент',
        emergencyAccess: 'экстренной Служба кариськисько',
        multilingualCalling: 'ӧте многоязычный',
        freeCalling: 'Дунтэк звонок',
        denLiveVideo: 'DEN Видеоагент Live',
        multilingualChatRoom: 'чат Многоязычный',
        denMarketplace: 'площадкаослэн вузкарон аэропортын DEN',
        qrCodeCreator: 'площадкаослэн вузкарон глобальный',
        ttyTtd: 'TTY/секытпель но тонгес служба понна кизёно ретрансляция tdd video',
        airlineAgents: 'Agenti leteckých spoločností',
        personalCall: 'Osobný hovor',
        phoneNumber: 'Telefónne číslo',
        selectAirline: 'Vyberte si agenta leteckej spoločnosti',
        airlineCalling: 'Volanie leteckej spoločnosti',
        connect: 'pripojiť',
    },
    latvian: {
        selectLanguage: 'Lūdzu, izvēlieties valodu',
        backMainMenu: 'Atpakaļ uz galveno izvēlni',
        helpSubtitle: 'Kā mēs varam jums palīdzēt šodien?',
        healthWellness: 'Veselības un labklājības pārbaude',
        denSurvey: 'DEN klientu apkalpošanas aptauja',
        emergencyAccess: 'Ārkārtas piekļuves pakalpojumi',
        multilingualCalling: 'Daudzvalodu zvanīšana',
        freeCalling: 'Bezmaksas zvani',
        denLiveVideo: 'DEN tiešraides video aģents',
        multilingualChatRoom: 'Daudzvalodu tērzēšanas telpa',
        denMarketplace: 'DEN lidostas tirgus',
        qrCodeCreator: 'Globālais tirgus',
        ttyTtd: 'TTY/TDD ir paredzēts video pārraides pakalpojumiem nedzirdīgajiem un vājdzirdīgajiem',
        airlineAgents: 'Aviokompānijas aģenti',
        personalCall: 'Personīgais zvans',
        phoneNumber: 'Tālruņa numurs',
        selectAirline: 'Izvēlieties savu aviokompānijas aģentu',
        airlineCalling: 'Aviokompānijas zvani',
        connect: 'Savienot',
    },
    yua: {
        selectLanguage: "Seleccione u t'aano",
        backMainMenu: "k'a' le noj bejo' menú",
        helpSubtitle: "wáaj bix je'el u páajtal k wáantik a bejla'e",
        healthWellness: 'Chequeo u toj óolal yéetel bienestar',
        denSurvey: 'Encuesta u Sergice u Clientes u DEN',
        emergencyAccess: "yéetel u muuk' láakal",
        multilingualCalling: 'Llamadas multilingües',
        freeCalling: 'Llamadas gratuitas',
        denLiveVideo: "aj nu'uktaj u vídeo en beetaj ich u TS'AIKo'OB",
        multilingualChatRoom: 'Sala u chat multilingüe',
        denMarketplace: "Mercado le aeropuerto TS'AIKO'OB",
        qrCodeCreator: 'Mercado global',
        ttyTtd: "TTY leti' TDD u ti'al yéetel retransmisión vídeo utia'al máako'ob sordas yéetel discapacidad auditiva",
        airlineAgents: 'Agentes aerolíneas',
        personalCall: "t'aan máaxo'ob",
        phoneNumber: "meyaj ku u nu'ukul t'aan",
        selectAirline: "Seleccione u aj nu'uktaj aerolínea",
        airlineCalling: 'Llamadas u aerolíneas',
        connect: 'Conectar',
    },
    serbian: {
        selectLanguage: 'Молимо изаберите свој језик',
        backMainMenu: 'Назад на главни мени',
        helpSubtitle: 'Како вам можемо помоћи данас?',
        healthWellness: 'Провера здравља и добробити',
        denSurvey: 'ДЕН Цустомер Сервице Анкета',
        emergencyAccess: 'Услуге приступа у хитним случајевима',
        multilingualCalling: 'Вишејезично позивање',
        freeCalling: 'Бесплатно позивање',
        denLiveVideo: 'ДЕН Ливе Видео Агент',
        multilingualChatRoom: 'Вишејезична соба за ћаскање',
        денМаркетплаце: 'ДЕН Аирпорт Маркетплаце',
        крЦодеЦреатор: 'Глобално тржиште',
        ttyTtd: 'ТТИ/ТДД је за услуге видео релеја за глуве и наглуве',
        airlineAgents: 'Агенти авио-компаније',
        personalCall: 'Лични позив',
        phoneNumber: 'Број телефона',
        selectAirline: 'Изаберите свог агента авиокомпаније',
        airlineCalling: 'Позивање авио компаније',
        connect: 'Повежи се',
    },
    shona: {
        selectLanguage: 'Ndapota sarudza mutauro wako',
        backMainMenu: 'Dzokera kuMain Menu',
        helpSubtitle: 'Tingakubatsira sei nhasi?',
        healthWellness: 'Ongororo yehutano uye hutano',
        denSurvey: 'DEN Mutengi Sevhisi ',
        emergencyAccess: 'Emergency Access Services',
        multilingualCalling: 'Kufona nemitauro yakawanda',
        freeCalling: 'Kufona Kwemahara',
        denLiveVideo: 'DEN munhu pavhidhiyo ',
        multilingualChatRoom: 'mitauro yakawanda ',
        denMarketplace: 'DEN airport pamusika ',
        qrCodeCreator: 'musika wepasi rose ',
        ttyTtd: 'TTY/TDD ndeyevhidhiyo relay masevhisi kune matsi nevasinganzwe',
        airlineAgents: 'Vamiririri vendege',
        personalCall: 'kufona kwako ',
        phoneNumber: 'Nhamba Yerunhare',
        selectAirline: 'Sarudza mumiriri wendege',
        airlineCalling: 'kufona kwendege ',
        connect: 'Batanidza',
    },
    samoan: {
        selectLanguage: "Fa'amolemole filifili lau gagana",
        backMainMenu: 'Toe i le Menu Autu',
        helpSubtitle: 'E faapefea ona matou fesoasoani atu ia te oe i le asō?',
        healthWellness: 'Siaki le Soifua Maloloina ma le Soifua Maloloina',
        denSurvey: "DEN Su'esu'ega Auaunaga Fa'atau",
        emergencyAccess: "Au'aunaga Avanoa Fa'afuase'i",
        multilingualCalling: 'Valaau e tele gagana',
        freeCalling: 'Valaau fua',
        denLiveVideo: 'DEN sui o vitio tuusao',
        multilingualChatRoom: "Potu Fetalafa'iga e tele gagana",
        denMarketplace: 'DEN Malae Vaalele Maketi',
        qrCodeCreator: "Fa'atauga Fa'alelalolagi",
        ttyTtd: "TTY/TDD e mo auaunaga fa'asalalau vitiō mo ē logonoa ma fa'alogo",
        airlineAgents: 'Soi o vaalele',
        personalCall: 'Valaau patino',
        phoneNumber: 'Numera Telefoni',
        selectAirline: 'Filifili lau sooupu vaalele',
        airlineCalling: 'Valaau i le Vaalele',
        connect: "Feso'ota'i",
    },
    de: {
        selectLanguage: 'Bitte wählen Sie Ihre Sprache',
        backMainMenu: 'Zurück zum Hauptmenü',
        helpSubtitle: 'Wie können wir Ihnen heute behilflich sein?',
        healthWellness: 'Gesundheits- und Wellness-Check',
        denSurvey: 'DEN-Kundendienstumfrage',
        emergencyAccess: 'Notfallzugangsdienste',
        multilingualCalling: 'Mehrsprachiger Anruf',
        freeCalling: 'Kostenloses Telefonieren',
        denLiveVideo: 'DEN Persönlicher Videoagent',
        multilingualChatRoom: 'Mehrsprachiger Chatraum',
        denMarketplace: 'Marktplatz für Flughäfen',
        qrCodeCreator: 'Globaler Marktplatz',
        ttyTtd: 'TTY/TDD ist für Videoübertragungsdienste für Gehörlose und Hörgeschädigte vorgesehen',
        airlineAgents: 'Airline-Agenten',
        personalCall: 'Persönlicher Anruf',
        phoneNumber: 'Telefonnummer',
        selectAirline: 'Wählen Sie Ihren Airline-Agenten aus',
        airlineCalling: 'Airline-Anrufe',
        connect: 'Verbinden',
    },
    'vi-VN': {
        selectLanguage: 'Hãy chọn ngôn ngữ của bạn',
        backMainMenu: 'Quay lại Menu Chính',
        helpSubtitle: 'Hôm nay chúng tôi có thể giúp gì cho bạn?',
        healthWellness: 'Kiểm tra sức khỏe và thể chất',
        denSurvey: 'Khảo sát dịch vụ khách hàng của DEN',
        emergencyAccess: 'Dịch vụ truy cập khẩn cấp',
        multilingualCalling: 'Gọi đa ngôn ngữ',
        freeCalling: 'Gọi miễn phí',
        denLiveVideo: 'Tác nhân video trực tiếp DEN',
        multilingualChatRoom: 'Phòng trò chuyện đa ngôn ngữ',
        denMarketplace: 'Thị trường sân bay DEN',
        qrCodeCreator: 'Thị trường toàn cầu',
        ttyTtd: 'TTY/TDD dành cho dịch vụ chuyển tiếp video dành cho người điếc và khiếm thính',
        airlineAgents: 'Đại lý hãng hàng không',
        personalCall: 'Cuộc gọi cá nhân',
        phoneNumber: 'Số điện thoại',
        selectAirline: 'Chọn đại lý hãng hàng không của bạn',
        airlineCalling: 'Gọi qua hãng hàng không',
        connect: 'Kết nối',
    },
    maori: {
        selectLanguage: 'Tīpakohia tō reo',
        backMainMenu: 'Hoki ki te Tahua Matua',
        helpSubtitle: 'Me pehea e taea ai e matou te awhina i a koe i tenei ra?',
        healthWellness: 'Tirohanga Hauora me te Ora',
        denSurvey: 'Tirohanga Ratonga Kaihoko DEN',
        emergencyAccess: 'Ratonga Uru ohorere',
        multilingualCalling: 'Waea reo maha',
        freeCalling: 'Waea Koreutu',
        denLiveVideo: 'DEN māngai ataata ora',
        multilingualChatRoom: 'Rūma Kōrerorero Rereha',
        denMarketplace: 'Maakete Tauranga rererangi DEN',
        qrCodeCreator: 'Maakete Ao',
        ttyTtd: 'Ko TTY/TDD mo nga ratonga kawe riipene whakaata mo te hunga turi me te hunga whakarongo',
        airlineAgents: 'Kaiwhakahaere rererangi',
        personalCall: 'Waea Whaiaro',
        phoneNumber: 'Tau Waea',
        selectAirline: 'Tīpakohia tō kaihoko waka rererangi',
        airlineCalling: 'Waea Rererangi',
        connect: 'Hono',
    },
    bashkir: {
        selectLanguage: 'зинһар, телегеҙҙе һайлағыҙ',
        backMainMenu: 'Vтөп менюға Ҡайтыу',
        helpSubtitle: 'бөгөн Һеҙгә Нисек ярҙам итә алабыҙ?',
        healthWellness: 'һаулыҡ торошон Тикшереү',
        denSurvey: 'den клиенттары Араһында Һорау алыу',
        emergencyAccess: 'ашығыс инеү Хеҙмәттәре',
        multilingualCalling: 'Күп Телле Саҡырыусы',
        freeCalling: 'Бушлай шылтыратыуҙар',
        denLiveVideo: 'Видеоагент DEN Live',
        multilingualChatRoom: 'Күп Телле чат',
        denMarketplace: 'DEN аэропортындағы Сауҙа майҙансығы',
        qrCodeCreator: 'Глобаль сауҙа майҙансығы',
        ttyTtd: 'TTY/TDD һаңғырауҙар һәм насар ишетеүселәр өсөн видео ретрансляция хеҙмәттәре өсөн тәғәйенләнгән',
        airlineAgents: 'авиакомпания Агенттары',
        personalCall: 'Шәхси шылтыратыу',
        phoneNumber: 'телефон Номеры',
        selectAirline: 'һеҙҙең авиакомпанияның агентын Һайлағыҙ',
        airlineCalling: 'авиакомпания Саҡырыуы',
        connect: 'Бәйләнешкә ',
    },
    pt: {
        selectLanguage: 'Por favor selecione seu idioma',
        backMainMenu: 'Voltar ao Menu Principal',
        helpSubtitle: 'Como podemos ajudá-lo hoje?',
        healthWellness: 'Verificação de saúde e bem-estar',
        denSurvey: 'Pesquisa de Atendimento ao Cliente DEN',
        emergencyAccess: 'Serviços de acesso de emergência',
        multilingualCalling: 'Chamadas multilíngues',
        freeCalling: 'Chamadas Gratuitas',
        denLiveVideo: 'Agente de vídeo ao vivo DEN',
        multilingualChatRoom: 'Sala de bate-papo multilíngue',
        denMarketplace: 'Mercado do Aeroporto DEN',
        qrCodeCreator: 'Mercado Global',
        ttyTtd: 'TTY/TDD é para serviços de retransmissão de vídeo para surdos e deficientes auditivos',
        airlineAgents: 'Agentes aéreos',
        personalCall: 'Chamada Pessoal',
        phoneNumber: 'Número de telefone',
        selectAirline: 'Selecione seu agente aéreo',
        airlineCalling: 'Chamada de companhia aérea',
        connect: 'Conectar',
    },
    bg: {
        selectLanguage: 'Моля, изберете вашия език',
        backMainMenu: 'Назад към главното меню',
        helpSubtitle: 'Как можем да ви помогнем днес?',
        healthWellness: 'Проверка на здравето и благосъстоянието',
        denSurvey: 'Проучване за обслужване на клиенти на DEN',
        emergencyAccess: 'Услуги за спешен достъп',
        multilingualCalling: 'Многоезично повикване',
        freeCalling: 'Безплатно обаждане',
        denLiveVideo: 'DEN видео агент на живо',
        multilingualChatRoom: 'Многоезична стая за чат',
        denMarketplace: 'Пазар на летище DEN',
        qrCodeCreator: 'Глобален пазар',
        ttyTtd: 'TTY/TDD е за услуги за видеопредаване за глухи и хора с увреден слух',
        airlineAgents: 'Агенти на авиокомпанията',
        personalCall: 'Лично обаждане',
        phoneNumber: 'Телефонен номер',
        selectAirline: 'Изберете вашия агент на авиокомпанията',
        airlineCalling: 'Обаждане от авиокомпания',
        connect: 'Свързване',
    },
    sindhi: {
        selectLanguage: 'مهرباني ڪري پنھنجي ٻولي چونڊيو',
        backMainMenu: 'مکين مينيو ڏانھن واپس',
        helpSubtitle: 'اڄ اسان توهان جي ڪيئن مدد ڪري سگهون ٿا؟',
        healthWellness: 'صحت ۽ تندرستي جي چڪاس',
        denSurvey: 'DEN ڪسٽمر سروس سروي',
        emergencyAccess: 'ايمرجنسي رسائي خدمتون',
        multilingualCalling: 'گهڻ لساني ڪالنگ',
        freeCalling: 'مفت ڪالنگ',
        denLiveVideo: 'DEN لائيو وڊيو ايجنٽ',
        multilingualChatRoom: 'گهڻ لساني چيٽ روم',
        denMarketplace: 'DEN ايئرپورٽ مارڪيٽ',
        qrCodeCreator: 'عالمي بازار',
        ttyTtd: 'TTY/TDD ٻوڙن ۽ ٻڌڻ کان معذور ماڻهن لاءِ وڊيو رلي سروسز لاءِ آهي',
        airlineAgents: 'ايئر لائن ايجنٽ',
        personalCall: 'ذاتي ڪال',
        phoneNumber: 'فون نمبر',
        selectAirline: 'پنهنجي ايئر لائن ايجنٽ کي چونڊيو',
        airlineCalling: 'ايئر لائن ڪالنگ',
        connect: 'ڳنڍيو',
    },
    yoruba: {
        selectLanguage: 'Jọwọ yan ede rẹ',
        backMainMenu: 'Pada si Akojọ aṣyn akọkọ',
        helpSubtitle: 'Bawo ni a ṣe le ran ọ lọwọ loni?',
        healthWellness: 'Ṣayẹwo Ilera ati Nini alafia',
        denSurvey: 'Iwadi Iṣẹ Onibara DEN',
        emergencyAccess: 'Awọn iṣẹ Wiwọle Pajawiri',
        multilingualCalling: 'Ìpè onímọ̀ èdè púpọ̀',
        freeCalling: 'Ipe Ọfẹ',
        denLiveVideo: 'Aṣojú Fidio Live DEN',
        multilingualChatRoom: 'Yàrá ìfọ̀rọ̀wérọ̀ oríṣiríṣi èdè',
        denMarketplace: 'Ibi ọjà Papa ọkọ ofurufu DEN',
        qrCodeCreator: 'Ibi Ọjà Agbaye',
        ttyTtd: 'TTY/TDD jẹ fun awọn iṣẹ isọdọtun fidio fun awọn aditi ati awọn alaiṣẹ igbọran',
        airlineAgents: 'Awọn aṣoju ofurufu',
        personalCall: 'Ipe Ti ara ẹni',
        phoneNumber: 'Nọ́ḿbà Fóònù',
        selectAirline: 'Yan aṣoju ọkọ ofurufu rẹ',
        airlineCalling: 'Pípe ọkọ̀ òfurufú',
        connect: 'Sopọ',
    },
    sl: {
        selectLanguage: 'Izberite svoj jezik',
        backMainMenu: 'Nazaj v glavni meni',
        helpSubtitle: 'Kako vam lahko danes pomagamo?',
        healthWellness: 'Pregled zdravja in dobrega počutja',
        denSurvey: 'Anketa o storitvah za stranke DEN',
        emergencyAccess: 'Storitve dostopa v sili',
        multilingualCalling: 'Večjezično klicanje',
        freeCalling: 'Brezplačno klicanje',
        denLiveVideo: 'DEN Video agent v živo',
        multilingualChatRoom: 'Večjezična klepetalnica',
        denMarketplace: 'DEN Tržnica na letališču',
        qrCodeCreator: 'Globalna tržnica',
        ttyTtd: 'TTY/TDD je za storitve video releja za gluhe in naglušne',
        airlineAgents: 'Letalski agenti',
        personalCall: 'Osebni klic',
        phoneNumber: 'Telefonska številka',
        selectAirline: 'Izberite svojega letalskega agenta',
        airlineCalling: 'Klicanje letalske družbe',
        connect: 'Povežite se',
    },
    tongan: {
        selectLanguage: 'Kataki ʻo fili hoʻo lea fakafonua',
        backMainMenu: 'foki ki he main menu',
        helpSubtitle: '',
        healthWellness: 'moʻui lelei mo Wellness sieke',
        denSurvey: 'savea tokoni ʻa e kasitomaa',
        emergencyAccess: 'ngaahi tokoni ki he meʻa fakatuʻupakee',
        multilingualCalling: 'uiuiʻi fakavahaʻa-puleʻanga',
        freeCalling: 'uiuiʻi taʻetotongi',
        denLiveVideo: 'den fakafofonga vitio hangatonu',
        multilingualChatRoom: 'loki talanoa multilingual',
        denMarketplace: 'maketi malaʻevakapuna DEN',
        qrCodeCreator: 'maketi fakamamani lahi',
        ttyTtd: ' ko e TTY/TDD ko e ngaahi tokoni ki he vitioo maʻa e kau tuli mo e fanongo ',
        airlineAgents: 'kau fakafofonga vakapuna',
        personalCall: 'uiuiʻi fakatautaha',
        phoneNumber: 'fika telefoni ',
        selectAirline: 'fili hoʻo fakafofonga vakapuna',
        airlineCalling: 'uiuiʻi ʻo e vakapuna',
        connect: 'fehokotaki',
    },
    otomi: {
        selectLanguage: 'Jaki ar mäte, seleccione ár hñä',
        backMainMenu: 'Volver ar menú principal jar hmä',
        helpSubtitle: "¿Honja podemos gi fa̲xki ga nu'bya?",
        healthWellness: 'Chequeo ar nzaki ne ar hño',
        denSurvey: 'Encuesta ar hontho ja ar cliente ar DEN',
        emergencyAccess: "'befi ya nthogi emergencia",
        multilingualCalling: 'Llamadas multilingües',
        freeCalling: 'Llamadas gratuitas',
        denLiveVideo: "Agente vídeo ho ga ku̲t'i DEN",
        multilingualChatRoom: 'Sala de chat multilingüe',
        denMarketplace: 'jár ta̲i ar aeropuerto DEN',
        qrCodeCreator: 'Jár ta̲i jar nxoge ximha',
        ttyTtd: "TTY yá TDD ar pa 'befi retransmisión vídeo pa ya jä'i sordas ne ko discapacidad auditiva",
        airlineAgents: "Agentes ar líneas ot'e nsani",
        personalCall: "Llamada jä'i",
        phoneNumber: "'bede ya teléfono",
        selectAirline: 'Seleccione ár agente aerolínea',
        airlineCalling: 'Llamadas aerolíneas',
        connect: 'Conectar',
    },
};
export default messages;
